const countrys = [
    { "Country": "China", "Year": "2010", "Income": 74.6, "Life_expectancy": 9530 },
    { "Country": "China", "Year": "2011", "Income": 75.1, "Life_expectancy": 10400 },
    { "Country": "China", "Year": "2012", "Income": 75.4, "Life_expectancy": 11100 },
    { "Country": "China", "Year": "2013", "Income": 75.7, "Life_expectancy": 12000 },
    { "Country": "China", "Year": "2014", "Income": 75.9, "Life_expectancy": 12800 },
    { "Country": "China", "Year": "2015", "Income": 76.3, "Life_expectancy": 13600 },
    { "Country": "China", "Year": "2016", "Income": 76.6, "Life_expectancy": 14400 },
    { "Country": "China", "Year": "2017", "Income": 76.8, "Life_expectancy": 15200 },
    { "Country": "China", "Year": "2018", "Income": 76.9, "Life_expectancy": 16000 },
    { "Country": "Colombia", "Year": "2010", "Income": 76.4, "Life_expectancy": 10900 },
    { "Country": "Colombia", "Year": "2011", "Income": 77, "Life_expectancy": 11500 },
    { "Country": "Colombia", "Year": "2012", "Income": 77.3, "Life_expectancy": 11800 },
    { "Country": "Colombia", "Year": "2013", "Income": 77.7, "Life_expectancy": 12300 },
    { "Country": "Colombia", "Year": "2014", "Income": 77.9, "Life_expectancy": 12700 },
    { "Country": "Colombia", "Year": "2015", "Income": 78, "Life_expectancy": 13000 },
    { "Country": "Colombia", "Year": "2016", "Income": 78.2, "Life_expectancy": 13100 },
    { "Country": "Colombia", "Year": "2017", "Income": 78.4, "Life_expectancy": 13400 },
    { "Country": "Colombia", "Year": "2018", "Income": 78.6, "Life_expectancy": 13700 },
    { "Country": "Cuba", "Year": "2010", "Income": 78.4, "Life_expectancy": 18300 },
    { "Country": "Cuba", "Year": "2011", "Income": 78.5, "Life_expectancy": 18800 },
    { "Country": "Cuba", "Year": "2012", "Income": 78.6, "Life_expectancy": 19400 },
    { "Country": "Cuba", "Year": "2013", "Income": 78.7, "Life_expectancy": 20000 },
    { "Country": "Cuba", "Year": "2014", "Income": 78.8, "Life_expectancy": 20000 },
    { "Country": "Cuba", "Year": "2015", "Income": 78.9, "Life_expectancy": 20000 },
    { "Country": "Cuba", "Year": "2016", "Income": 79, "Life_expectancy": 20000 },
    { "Country": "Cuba", "Year": "2017", "Income": 79.2, "Life_expectancy": 20000 },
    { "Country": "Cuba", "Year": "2018", "Income": 79.3, "Life_expectancy": 20000 },
    { "Country": "South Africa", "Year": "2010", "Income": 54.3, "Life_expectancy": 12000 },
    { "Country": "South Africa", "Year": "2011", "Income": 55.8, "Life_expectancy": 12200 },
    { "Country": "South Africa", "Year": "2012", "Income": 57.5, "Life_expectancy": 12300 },
    { "Country": "South Africa", "Year": "2013", "Income": 59.2, "Life_expectancy": 12400 },
    { "Country": "South Africa", "Year": "2014", "Income": 60.6, "Life_expectancy": 12500 },
    { "Country": "South Africa", "Year": "2015", "Income": 61.8, "Life_expectancy": 12400 },
    { "Country": "South Africa", "Year": "2016", "Income": 62.4, "Life_expectancy": 12300 },
    { "Country": "South Africa", "Year": "2017", "Income": 63, "Life_expectancy": 12300 },
    { "Country": "South Africa", "Year": "2018", "Income": 63.5, "Life_expectancy": 12300 },
    { "Country": "Thailand", "Year": "2010", "Income": 76.6, "Life_expectancy": 13500 },
    { "Country": "Thailand", "Year": "2011", "Income": 76.9, "Life_expectancy": 13500 },
    { "Country": "Thailand", "Year": "2012", "Income": 77.2, "Life_expectancy": 14400 },
    { "Country": "Thailand", "Year": "2013", "Income": 77.3, "Life_expectancy": 14800 },
    { "Country": "Thailand", "Year": "2014", "Income": 77.5, "Life_expectancy": 14900 },
    { "Country": "Thailand", "Year": "2015", "Income": 77.6, "Life_expectancy": 15200 },
    { "Country": "Thailand", "Year": "2016", "Income": 77.8, "Life_expectancy": 15700 },
    { "Country": "Thailand", "Year": "2017", "Income": 78, "Life_expectancy": 16300 },
    { "Country": "Thailand", "Year": "2018", "Income": 78.2, "Life_expectancy": 16900 }
];
export default countrys;