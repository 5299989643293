import React, { Component } from 'react';
import { Divider } from 'antd';

export default class Style extends Component {

    render() {
        return (
            <div>
                <Divider>Style</Divider>
            </div>
        )
    }
}
