export default {
	//Resource DND
	DND_IMAGE: 'DND_IMAGE',
	DND_CHART: 'DND_CHART',
	DND_AUDIO: 'DND_AUDIO',
	DND_VIDEO: 'DND_VIDEO',
	DND_GIF: 'DND_GIF',
	//Chart DND
	DND_MAPPING: 'DND_MAPPING',
	DND_CHART_ANIMATION: 'DND_CHART_ANIMATION',
	//Tool DND
	DND_ANIMATION: 'DND_ANIMATION',
}