import { connect } from 'react-redux';
import ProjectView from './ProjectView';

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProjectView)