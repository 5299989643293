// import * as d3 from 'd3';
import _ from 'lodash';

const getSeries = (rawData, encoding) => {
    if(('color' in encoding) && !_.isEmpty(encoding.color))
        return Array.from(new Set(rawData.map(d => d[encoding.color.field])));
    else return [];
}

const getLeastSquares = (X, Y) => {
    let ret = {}
  
    let sumX = 0
    let sumY = 0
    let sumXY = 0
    let sumXSq = 0
    let N = X.length
  
    for(let i = 0; i < N; ++i) {
      sumX += X[i]
      sumY += Y[i]
      sumXY += X[i] * Y[i]
      sumXSq += X[i] * X[i]
    }
  
    ret.m = ((sumXY - sumX * sumY / N) ) / (sumXSq - sumX * sumX / N)
    ret.b = sumY / N - ret.m * sumX / N
  
    return ret;
  }

/* eslint quotes: off, comma-dangle: off, quote-props: off */
const widthsMap = {
  "andale mono": {
    "0": [
      60,
      60,
      60,
      60
    ],
    "1": [
      60,
      60,
      60,
      60
    ],
    "2": [
      60,
      60,
      60,
      60
    ],
    "3": [
      60,
      60,
      60,
      60
    ],
    "4": [
      60,
      60,
      60,
      60
    ],
    "5": [
      60,
      60,
      60,
      60
    ],
    "6": [
      60,
      60,
      60,
      60
    ],
    "7": [
      60,
      60,
      60,
      60
    ],
    "8": [
      60,
      60,
      60,
      60
    ],
    "9": [
      60,
      60,
      60,
      60
    ],
    " ": [
      60,
      60,
      60,
      60
    ],
    "!": [
      60,
      60,
      60,
      60
    ],
    "\"": [
      60,
      60,
      60,
      60
    ],
    "#": [
      60,
      60,
      60,
      60
    ],
    "$": [
      60,
      60,
      60,
      60
    ],
    "%": [
      60,
      60,
      60,
      60
    ],
    "&": [
      60,
      60,
      60,
      60
    ],
    "'": [
      60,
      60,
      60,
      60
    ],
    "(": [
      60,
      60,
      60,
      60
    ],
    ")": [
      60,
      60,
      60,
      60
    ],
    "*": [
      60,
      60,
      60,
      60
    ],
    "+": [
      60,
      60,
      60,
      60
    ],
    ",": [
      60,
      60,
      60,
      60
    ],
    "-": [
      60,
      60,
      60,
      60
    ],
    ".": [
      60,
      60,
      60,
      60
    ],
    "/": [
      60,
      60,
      60,
      60
    ],
    ":": [
      60,
      60,
      60,
      60
    ],
    ";": [
      60,
      60,
      60,
      60
    ],
    "<": [
      60,
      60,
      60,
      60
    ],
    "=": [
      60,
      60,
      60,
      60
    ],
    ">": [
      60,
      60,
      60,
      60
    ],
    "?": [
      60,
      60,
      60,
      60
    ],
    "@": [
      60,
      60,
      60,
      60
    ],
    "A": [
      60,
      60,
      60,
      60
    ],
    "B": [
      60,
      60,
      60,
      60
    ],
    "C": [
      60,
      60,
      60,
      60
    ],
    "D": [
      60,
      60,
      60,
      60
    ],
    "E": [
      60,
      60,
      60,
      60
    ],
    "F": [
      60,
      60,
      60,
      60
    ],
    "G": [
      60,
      60,
      60,
      60
    ],
    "H": [
      60,
      60,
      60,
      60
    ],
    "I": [
      60,
      60,
      60,
      60
    ],
    "J": [
      60,
      60,
      60,
      60
    ],
    "K": [
      60,
      60,
      60,
      60
    ],
    "L": [
      60,
      60,
      60,
      60
    ],
    "M": [
      60,
      60,
      60,
      60
    ],
    "N": [
      60,
      60,
      60,
      60
    ],
    "O": [
      60,
      60,
      60,
      60
    ],
    "P": [
      60,
      60,
      60,
      60
    ],
    "Q": [
      60,
      60,
      60,
      60
    ],
    "R": [
      60,
      60,
      60,
      60
    ],
    "S": [
      60,
      60,
      60,
      60
    ],
    "T": [
      60,
      60,
      60,
      60
    ],
    "U": [
      60,
      60,
      60,
      60
    ],
    "V": [
      60,
      60,
      60,
      60
    ],
    "W": [
      60,
      60,
      60,
      60
    ],
    "X": [
      60,
      60,
      60,
      60
    ],
    "Y": [
      60,
      60,
      60,
      60
    ],
    "Z": [
      60,
      60,
      60,
      60
    ],
    "[": [
      60,
      60,
      60,
      60
    ],
    "\\": [
      60,
      60,
      60,
      60
    ],
    "]": [
      60,
      60,
      60,
      60
    ],
    "^": [
      60,
      60,
      60,
      60
    ],
    "_": [
      60,
      60,
      60,
      60
    ],
    "`": [
      60,
      60,
      60,
      60
    ],
    "a": [
      60,
      60,
      60,
      60
    ],
    "b": [
      60,
      60,
      60,
      60
    ],
    "c": [
      60,
      60,
      60,
      60
    ],
    "d": [
      60,
      60,
      60,
      60
    ],
    "e": [
      60,
      60,
      60,
      60
    ],
    "f": [
      60,
      60,
      60,
      60
    ],
    "g": [
      60,
      60,
      60,
      60
    ],
    "h": [
      60,
      60,
      60,
      60
    ],
    "i": [
      60,
      60,
      60,
      60
    ],
    "j": [
      60,
      60,
      60,
      60
    ],
    "k": [
      60,
      60,
      60,
      60
    ],
    "l": [
      60,
      60,
      60,
      60
    ],
    "m": [
      60,
      60,
      60,
      60
    ],
    "n": [
      60,
      60,
      60,
      60
    ],
    "o": [
      60,
      60,
      60,
      60
    ],
    "p": [
      60,
      60,
      60,
      60
    ],
    "q": [
      60,
      60,
      60,
      60
    ],
    "r": [
      60,
      60,
      60,
      60
    ],
    "s": [
      60,
      60,
      60,
      60
    ],
    "t": [
      60,
      60,
      60,
      60
    ],
    "u": [
      60,
      60,
      60,
      60
    ],
    "v": [
      60,
      60,
      60,
      60
    ],
    "w": [
      60,
      60,
      60,
      60
    ],
    "x": [
      60,
      60,
      60,
      60
    ],
    "y": [
      60,
      60,
      60,
      60
    ],
    "z": [
      60,
      60,
      60,
      60
    ],
    "{": [
      60,
      60,
      60,
      60
    ],
    "|": [
      60,
      60,
      60,
      60
    ],
    "}": [
      60,
      60,
      60,
      60
    ],
    "~": [
      60,
      60,
      60,
      60
    ]
  },
  "arial": {
    "0": [
      56,
      56,
      56,
      56
    ],
    "1": [
      56,
      56,
      56,
      56
    ],
    "2": [
      56,
      56,
      56,
      56
    ],
    "3": [
      56,
      56,
      56,
      56
    ],
    "4": [
      56,
      56,
      56,
      56
    ],
    "5": [
      56,
      56,
      56,
      56
    ],
    "6": [
      56,
      56,
      56,
      56
    ],
    "7": [
      56,
      56,
      56,
      56
    ],
    "8": [
      56,
      56,
      56,
      56
    ],
    "9": [
      56,
      56,
      56,
      56
    ],
    " ": [
      28,
      28,
      28,
      28
    ],
    "!": [
      28,
      33,
      28,
      33
    ],
    "\"": [
      35,
      47,
      35,
      47
    ],
    "#": [
      56,
      56,
      56,
      56
    ],
    "$": [
      56,
      56,
      56,
      56
    ],
    "%": [
      89,
      89,
      89,
      89
    ],
    "&": [
      67,
      72,
      67,
      72
    ],
    "'": [
      19,
      24,
      19,
      24
    ],
    "(": [
      33,
      33,
      33,
      33
    ],
    ")": [
      33,
      33,
      33,
      33
    ],
    "*": [
      39,
      39,
      39,
      39
    ],
    "+": [
      58,
      58,
      58,
      58
    ],
    ",": [
      28,
      28,
      28,
      28
    ],
    "-": [
      33,
      33,
      33,
      33
    ],
    ".": [
      28,
      28,
      28,
      28
    ],
    "/": [
      28,
      28,
      28,
      28
    ],
    ":": [
      28,
      33,
      28,
      33
    ],
    ";": [
      28,
      33,
      28,
      33
    ],
    "<": [
      58,
      58,
      58,
      58
    ],
    "=": [
      58,
      58,
      58,
      58
    ],
    ">": [
      58,
      58,
      58,
      58
    ],
    "?": [
      56,
      61,
      56,
      61
    ],
    "@": [
      102,
      98,
      102,
      98
    ],
    "A": [
      67,
      72,
      67,
      72
    ],
    "B": [
      67,
      72,
      67,
      72
    ],
    "C": [
      72,
      72,
      72,
      72
    ],
    "D": [
      72,
      72,
      72,
      72
    ],
    "E": [
      67,
      67,
      67,
      67
    ],
    "F": [
      61,
      61,
      61,
      61
    ],
    "G": [
      78,
      78,
      78,
      78
    ],
    "H": [
      72,
      72,
      72,
      72
    ],
    "I": [
      28,
      28,
      28,
      28
    ],
    "J": [
      50,
      56,
      50,
      56
    ],
    "K": [
      67,
      72,
      67,
      72
    ],
    "L": [
      56,
      61,
      56,
      61
    ],
    "M": [
      83,
      83,
      83,
      83
    ],
    "N": [
      72,
      72,
      72,
      72
    ],
    "O": [
      78,
      78,
      78,
      78
    ],
    "P": [
      67,
      67,
      67,
      67
    ],
    "Q": [
      78,
      78,
      78,
      78
    ],
    "R": [
      72,
      72,
      72,
      72
    ],
    "S": [
      67,
      67,
      67,
      67
    ],
    "T": [
      61,
      61,
      61,
      61
    ],
    "U": [
      72,
      72,
      72,
      72
    ],
    "V": [
      67,
      67,
      67,
      67
    ],
    "W": [
      94,
      94,
      94,
      94
    ],
    "X": [
      67,
      67,
      67,
      67
    ],
    "Y": [
      67,
      67,
      67,
      67
    ],
    "Z": [
      61,
      61,
      61,
      61
    ],
    "[": [
      28,
      33,
      28,
      33
    ],
    "\\": [
      28,
      28,
      28,
      28
    ],
    "]": [
      28,
      33,
      28,
      33
    ],
    "^": [
      47,
      58,
      47,
      58
    ],
    "_": [
      56,
      56,
      56,
      56
    ],
    "`": [
      33,
      33,
      33,
      33
    ],
    "a": [
      56,
      56,
      56,
      56
    ],
    "b": [
      56,
      61,
      56,
      61
    ],
    "c": [
      50,
      56,
      50,
      56
    ],
    "d": [
      56,
      61,
      56,
      61
    ],
    "e": [
      56,
      56,
      56,
      56
    ],
    "f": [
      28,
      33,
      28,
      33
    ],
    "g": [
      56,
      61,
      56,
      61
    ],
    "h": [
      56,
      61,
      56,
      61
    ],
    "i": [
      22,
      28,
      22,
      28
    ],
    "j": [
      22,
      28,
      22,
      28
    ],
    "k": [
      50,
      56,
      50,
      56
    ],
    "l": [
      22,
      28,
      22,
      28
    ],
    "m": [
      83,
      89,
      83,
      89
    ],
    "n": [
      56,
      61,
      56,
      61
    ],
    "o": [
      56,
      61,
      56,
      61
    ],
    "p": [
      56,
      61,
      56,
      61
    ],
    "q": [
      56,
      61,
      56,
      61
    ],
    "r": [
      33,
      39,
      33,
      39
    ],
    "s": [
      50,
      56,
      50,
      56
    ],
    "t": [
      28,
      33,
      28,
      33
    ],
    "u": [
      56,
      61,
      56,
      61
    ],
    "v": [
      50,
      56,
      50,
      56
    ],
    "w": [
      72,
      78,
      72,
      78
    ],
    "x": [
      50,
      56,
      50,
      56
    ],
    "y": [
      50,
      56,
      50,
      56
    ],
    "z": [
      50,
      50,
      50,
      50
    ],
    "{": [
      33,
      39,
      33,
      39
    ],
    "|": [
      26,
      28,
      26,
      28
    ],
    "}": [
      33,
      39,
      33,
      39
    ],
    "~": [
      58,
      58,
      58,
      58
    ]
  },
  "avenir": {
    "0": [
      56,
      59,
      56,
      59
    ],
    "1": [
      56,
      59,
      56,
      59
    ],
    "2": [
      56,
      59,
      56,
      59
    ],
    "3": [
      56,
      59,
      56,
      59
    ],
    "4": [
      56,
      59,
      56,
      59
    ],
    "5": [
      56,
      59,
      56,
      59
    ],
    "6": [
      56,
      59,
      56,
      59
    ],
    "7": [
      56,
      59,
      56,
      59
    ],
    "8": [
      56,
      59,
      56,
      59
    ],
    "9": [
      56,
      59,
      56,
      59
    ],
    " ": [
      28,
      30,
      28,
      30
    ],
    "!": [
      28,
      30,
      28,
      30
    ],
    "\"": [
      52,
      52,
      52,
      52
    ],
    "#": [
      56,
      59,
      56,
      59
    ],
    "$": [
      56,
      59,
      56,
      59
    ],
    "%": [
      83,
      91,
      83,
      91
    ],
    "&": [
      70,
      74,
      70,
      74
    ],
    "'": [
      26,
      30,
      26,
      30
    ],
    "(": [
      26,
      30,
      26,
      30
    ],
    ")": [
      26,
      30,
      26,
      30
    ],
    "*": [
      44,
      46,
      44,
      46
    ],
    "+": [
      67,
      67,
      67,
      67
    ],
    ",": [
      28,
      30,
      28,
      30
    ],
    "-": [
      33,
      32,
      33,
      32
    ],
    ".": [
      28,
      30,
      28,
      30
    ],
    "/": [
      37,
      39,
      37,
      39
    ],
    ":": [
      28,
      30,
      28,
      30
    ],
    ";": [
      28,
      30,
      28,
      30
    ],
    "<": [
      67,
      67,
      67,
      67
    ],
    "=": [
      67,
      67,
      67,
      67
    ],
    ">": [
      67,
      67,
      67,
      67
    ],
    "?": [
      48,
      52,
      48,
      52
    ],
    "@": [
      80,
      80,
      80,
      80
    ],
    "A": [
      69,
      72,
      69,
      72
    ],
    "B": [
      63,
      65,
      63,
      65
    ],
    "C": [
      70,
      69,
      70,
      69
    ],
    "D": [
      74,
      76,
      74,
      76
    ],
    "E": [
      59,
      61,
      59,
      61
    ],
    "F": [
      56,
      57,
      56,
      57
    ],
    "G": [
      78,
      78,
      78,
      78
    ],
    "H": [
      72,
      74,
      72,
      74
    ],
    "I": [
      26,
      28,
      26,
      28
    ],
    "J": [
      48,
      50,
      48,
      50
    ],
    "K": [
      63,
      69,
      63,
      69
    ],
    "L": [
      50,
      52,
      50,
      52
    ],
    "M": [
      89,
      93,
      89,
      93
    ],
    "N": [
      78,
      80,
      78,
      80
    ],
    "O": [
      83,
      83,
      83,
      83
    ],
    "P": [
      57,
      61,
      57,
      61
    ],
    "Q": [
      83,
      85,
      83,
      85
    ],
    "R": [
      59,
      63,
      59,
      63
    ],
    "S": [
      56,
      57,
      56,
      57
    ],
    "T": [
      57,
      57,
      57,
      57
    ],
    "U": [
      69,
      72,
      69,
      72
    ],
    "V": [
      61,
      67,
      61,
      67
    ],
    "W": [
      94,
      98,
      94,
      98
    ],
    "X": [
      63,
      69,
      63,
      69
    ],
    "Y": [
      57,
      63,
      57,
      63
    ],
    "Z": [
      56,
      61,
      56,
      61
    ],
    "[": [
      26,
      28,
      26,
      28
    ],
    "\\": [
      37,
      39,
      37,
      39
    ],
    "]": [
      26,
      28,
      26,
      28
    ],
    "^": [
      67,
      67,
      67,
      67
    ],
    "_": [
      50,
      50,
      50,
      50
    ],
    "`": [
      24,
      26,
      24,
      26
    ],
    "a": [
      52,
      54,
      52,
      54
    ],
    "b": [
      61,
      63,
      61,
      63
    ],
    "c": [
      48,
      48,
      48,
      48
    ],
    "d": [
      61,
      63,
      61,
      63
    ],
    "e": [
      56,
      57,
      56,
      57
    ],
    "f": [
      30,
      35,
      30,
      35
    ],
    "g": [
      61,
      63,
      61,
      63
    ],
    "h": [
      56,
      57,
      56,
      57
    ],
    "i": [
      24,
      26,
      24,
      26
    ],
    "j": [
      24,
      26,
      24,
      26
    ],
    "k": [
      48,
      54,
      48,
      54
    ],
    "l": [
      24,
      26,
      24,
      26
    ],
    "m": [
      85,
      87,
      85,
      87
    ],
    "n": [
      56,
      57,
      56,
      57
    ],
    "o": [
      59,
      61,
      59,
      61
    ],
    "p": [
      61,
      63,
      61,
      63
    ],
    "q": [
      61,
      63,
      61,
      63
    ],
    "r": [
      33,
      39,
      33,
      39
    ],
    "s": [
      43,
      44,
      43,
      44
    ],
    "t": [
      33,
      39,
      33,
      39
    ],
    "u": [
      56,
      57,
      56,
      57
    ],
    "v": [
      48,
      52,
      48,
      52
    ],
    "w": [
      72,
      80,
      72,
      80
    ],
    "x": [
      48,
      54,
      48,
      54
    ],
    "y": [
      48,
      52,
      48,
      52
    ],
    "z": [
      43,
      48,
      43,
      48
    ],
    "{": [
      26,
      28,
      26,
      28
    ],
    "|": [
      22,
      22,
      22,
      22
    ],
    "}": [
      26,
      28,
      26,
      28
    ],
    "~": [
      67,
      67,
      67,
      67
    ]
  },
  "avenir next": {
    "0": [
      58,
      65,
      58,
      64
    ],
    "1": [
      58,
      65,
      58,
      64
    ],
    "2": [
      58,
      65,
      58,
      64
    ],
    "3": [
      58,
      65,
      58,
      64
    ],
    "4": [
      58,
      65,
      58,
      64
    ],
    "5": [
      58,
      65,
      58,
      64
    ],
    "6": [
      58,
      65,
      58,
      64
    ],
    "7": [
      58,
      65,
      58,
      64
    ],
    "8": [
      58,
      65,
      58,
      64
    ],
    "9": [
      58,
      65,
      58,
      64
    ],
    " ": [
      25,
      25,
      25,
      25
    ],
    "!": [
      33,
      36,
      28,
      35
    ],
    "\"": [
      41,
      50,
      40,
      49
    ],
    "#": [
      56,
      60,
      56,
      60
    ],
    "$": [
      58,
      65,
      58,
      64
    ],
    "%": [
      83,
      91,
      83,
      91
    ],
    "&": [
      70,
      78,
      70,
      78
    ],
    "'": [
      26,
      30,
      26,
      30
    ],
    "(": [
      30,
      34,
      30,
      34
    ],
    ")": [
      30,
      34,
      30,
      34
    ],
    "*": [
      44,
      49,
      44,
      49
    ],
    "+": [
      67,
      67,
      67,
      67
    ],
    ",": [
      26,
      30,
      26,
      30
    ],
    "-": [
      32,
      33,
      32,
      33
    ],
    ".": [
      26,
      30,
      26,
      30
    ],
    "/": [
      37,
      42,
      37,
      41
    ],
    ":": [
      30,
      33,
      30,
      33
    ],
    ";": [
      30,
      33,
      30,
      33
    ],
    "<": [
      67,
      67,
      67,
      67
    ],
    "=": [
      67,
      67,
      67,
      67
    ],
    ">": [
      67,
      67,
      67,
      67
    ],
    "?": [
      48,
      55,
      48,
      54
    ],
    "@": [
      80,
      80,
      80,
      80
    ],
    "A": [
      70,
      73,
      70,
      71
    ],
    "B": [
      64,
      67,
      62,
      66
    ],
    "C": [
      72,
      70,
      72,
      68
    ],
    "D": [
      76,
      77,
      76,
      77
    ],
    "E": [
      59,
      61,
      58,
      59
    ],
    "F": [
      56,
      58,
      55,
      56
    ],
    "G": [
      78,
      77,
      77,
      75
    ],
    "H": [
      72,
      76,
      71,
      76
    ],
    "I": [
      26,
      32,
      26,
      32
    ],
    "J": [
      49,
      53,
      48,
      53
    ],
    "K": [
      63,
      71,
      59,
      68
    ],
    "L": [
      51,
      53,
      51,
      53
    ],
    "M": [
      89,
      96,
      89,
      95
    ],
    "N": [
      76,
      79,
      75,
      79
    ],
    "O": [
      85,
      85,
      83,
      83
    ],
    "P": [
      58,
      64,
      58,
      64
    ],
    "Q": [
      84,
      86,
      84,
      84
    ],
    "R": [
      60,
      66,
      60,
      66
    ],
    "S": [
      56,
      59,
      56,
      59
    ],
    "T": [
      57,
      58,
      55,
      55
    ],
    "U": [
      71,
      74,
      71,
      74
    ],
    "V": [
      62,
      68,
      60,
      67
    ],
    "W": [
      97,
      103,
      96,
      101
    ],
    "X": [
      65,
      70,
      62,
      69
    ],
    "Y": [
      60,
      67,
      56,
      61
    ],
    "Z": [
      57,
      64,
      57,
      62
    ],
    "[": [
      30,
      34,
      30,
      34
    ],
    "\\": [
      37,
      42,
      37,
      42
    ],
    "]": [
      30,
      34,
      30,
      34
    ],
    "^": [
      67,
      69,
      67,
      69
    ],
    "_": [
      50,
      50,
      50,
      50
    ],
    "`": [
      24,
      30,
      24,
      30
    ],
    "a": [
      53,
      57,
      53,
      56
    ],
    "b": [
      64,
      66,
      63,
      66
    ],
    "c": [
      50,
      50,
      50,
      50
    ],
    "d": [
      64,
      66,
      62,
      66
    ],
    "e": [
      57,
      60,
      57,
      59
    ],
    "f": [
      30,
      36,
      30,
      36
    ],
    "g": [
      63,
      66,
      63,
      66
    ],
    "h": [
      58,
      62,
      58,
      62
    ],
    "i": [
      25,
      30,
      25,
      29
    ],
    "j": [
      25,
      30,
      25,
      30
    ],
    "k": [
      51,
      60,
      49,
      57
    ],
    "l": [
      25,
      30,
      25,
      30
    ],
    "m": [
      88,
      92,
      87,
      93
    ],
    "n": [
      58,
      62,
      58,
      62
    ],
    "o": [
      61,
      63,
      60,
      63
    ],
    "p": [
      64,
      66,
      63,
      66
    ],
    "q": [
      64,
      66,
      62,
      66
    ],
    "r": [
      36,
      40,
      35,
      38
    ],
    "s": [
      44,
      47,
      44,
      46
    ],
    "t": [
      32,
      37,
      31,
      36
    ],
    "u": [
      58,
      61,
      58,
      61
    ],
    "v": [
      49,
      57,
      48,
      55
    ],
    "w": [
      75,
      87,
      73,
      86
    ],
    "x": [
      48,
      59,
      48,
      56
    ],
    "y": [
      49,
      57,
      48,
      56
    ],
    "z": [
      44,
      51,
      44,
      51
    ],
    "{": [
      30,
      34,
      30,
      34
    ],
    "|": [
      22,
      24,
      22,
      24
    ],
    "}": [
      30,
      34,
      30,
      34
    ],
    "~": [
      67,
      67,
      67,
      67
    ]
  },
  "comic sans ms": {
    "0": [
      61,
      61,
      61,
      61
    ],
    "1": [
      45,
      61,
      45,
      61
    ],
    "2": [
      61,
      61,
      61,
      61
    ],
    "3": [
      61,
      61,
      61,
      61
    ],
    "4": [
      61,
      61,
      61,
      61
    ],
    "5": [
      61,
      61,
      61,
      61
    ],
    "6": [
      61,
      61,
      61,
      61
    ],
    "7": [
      61,
      61,
      61,
      61
    ],
    "8": [
      61,
      61,
      61,
      61
    ],
    "9": [
      61,
      61,
      61,
      61
    ],
    " ": [
      30,
      48,
      30,
      48
    ],
    "!": [
      24,
      24,
      24,
      24
    ],
    "\"": [
      42,
      44,
      42,
      44
    ],
    "#": [
      84,
      84,
      84,
      84
    ],
    "$": [
      69,
      69,
      69,
      69
    ],
    "%": [
      82,
      82,
      82,
      82
    ],
    "&": [
      65,
      65,
      65,
      65
    ],
    "'": [
      39,
      43,
      39,
      43
    ],
    "(": [
      37,
      37,
      37,
      37
    ],
    ")": [
      37,
      37,
      37,
      37
    ],
    "*": [
      53,
      53,
      53,
      53
    ],
    "+": [
      48,
      61,
      48,
      61
    ],
    ",": [
      28,
      43,
      28,
      43
    ],
    "-": [
      42,
      61,
      42,
      61
    ],
    ".": [
      25,
      43,
      25,
      43
    ],
    "/": [
      51,
      51,
      51,
      51
    ],
    ":": [
      30,
      43,
      30,
      43
    ],
    ";": [
      30,
      43,
      30,
      43
    ],
    "<": [
      38,
      61,
      38,
      61
    ],
    "=": [
      51,
      61,
      51,
      61
    ],
    ">": [
      38,
      61,
      38,
      61
    ],
    "?": [
      52,
      57,
      52,
      57
    ],
    "@": [
      93,
      93,
      93,
      93
    ],
    "A": [
      73,
      73,
      73,
      73
    ],
    "B": [
      63,
      63,
      63,
      63
    ],
    "C": [
      60,
      62,
      60,
      62
    ],
    "D": [
      72,
      72,
      72,
      72
    ],
    "E": [
      62,
      62,
      62,
      62
    ],
    "F": [
      61,
      61,
      61,
      61
    ],
    "G": [
      68,
      68,
      68,
      68
    ],
    "H": [
      77,
      77,
      77,
      77
    ],
    "I": [
      55,
      55,
      55,
      55
    ],
    "J": [
      67,
      67,
      67,
      67
    ],
    "K": [
      61,
      61,
      61,
      61
    ],
    "L": [
      55,
      55,
      55,
      55
    ],
    "M": [
      88,
      88,
      88,
      88
    ],
    "N": [
      80,
      81,
      80,
      81
    ],
    "O": [
      80,
      80,
      80,
      80
    ],
    "P": [
      52,
      53,
      52,
      53
    ],
    "Q": [
      88,
      88,
      88,
      88
    ],
    "R": [
      63,
      64,
      63,
      64
    ],
    "S": [
      69,
      69,
      69,
      69
    ],
    "T": [
      68,
      70,
      68,
      70
    ],
    "U": [
      74,
      74,
      74,
      74
    ],
    "V": [
      65,
      67,
      65,
      67
    ],
    "W": [
      104,
      104,
      104,
      104
    ],
    "X": [
      72,
      72,
      72,
      72
    ],
    "Y": [
      64,
      64,
      64,
      64
    ],
    "Z": [
      69,
      69,
      69,
      69
    ],
    "[": [
      38,
      38,
      38,
      38
    ],
    "\\": [
      55,
      55,
      55,
      55
    ],
    "]": [
      38,
      38,
      38,
      38
    ],
    "^": [
      58,
      61,
      58,
      61
    ],
    "_": [
      63,
      63,
      63,
      63
    ],
    "`": [
      56,
      56,
      56,
      56
    ],
    "a": [
      51,
      56,
      51,
      56
    ],
    "b": [
      59,
      59,
      59,
      59
    ],
    "c": [
      51,
      51,
      51,
      51
    ],
    "d": [
      59,
      59,
      59,
      59
    ],
    "e": [
      55,
      56,
      55,
      56
    ],
    "f": [
      51,
      51,
      51,
      51
    ],
    "g": [
      53,
      53,
      53,
      53
    ],
    "h": [
      58,
      58,
      58,
      58
    ],
    "i": [
      28,
      28,
      28,
      28
    ],
    "j": [
      40,
      40,
      40,
      40
    ],
    "k": [
      54,
      54,
      54,
      54
    ],
    "l": [
      27,
      27,
      27,
      27
    ],
    "m": [
      78,
      78,
      78,
      78
    ],
    "n": [
      52,
      52,
      52,
      52
    ],
    "o": [
      53,
      53,
      53,
      53
    ],
    "p": [
      53,
      53,
      53,
      53
    ],
    "q": [
      52,
      52,
      52,
      52
    ],
    "r": [
      48,
      48,
      48,
      48
    ],
    "s": [
      49,
      49,
      49,
      49
    ],
    "t": [
      47,
      47,
      47,
      47
    ],
    "u": [
      52,
      52,
      52,
      52
    ],
    "v": [
      49,
      49,
      49,
      49
    ],
    "w": [
      68,
      68,
      68,
      68
    ],
    "x": [
      59,
      59,
      59,
      59
    ],
    "y": [
      52,
      55,
      52,
      55
    ],
    "z": [
      54,
      54,
      54,
      54
    ],
    "{": [
      37,
      37,
      37,
      37
    ],
    "|": [
      42,
      42,
      42,
      42
    ],
    "}": [
      37,
      37,
      37,
      37
    ],
    "~": [
      60,
      61,
      60,
      61
    ]
  },
  "courier new": {
    "0": [
      60,
      60,
      60,
      60
    ],
    "1": [
      60,
      60,
      60,
      60
    ],
    "2": [
      60,
      60,
      60,
      60
    ],
    "3": [
      60,
      60,
      60,
      60
    ],
    "4": [
      60,
      60,
      60,
      60
    ],
    "5": [
      60,
      60,
      60,
      60
    ],
    "6": [
      60,
      60,
      60,
      60
    ],
    "7": [
      60,
      60,
      60,
      60
    ],
    "8": [
      60,
      60,
      60,
      60
    ],
    "9": [
      60,
      60,
      60,
      60
    ],
    " ": [
      60,
      60,
      60,
      60
    ],
    "!": [
      60,
      60,
      60,
      60
    ],
    "\"": [
      60,
      60,
      60,
      60
    ],
    "#": [
      60,
      60,
      60,
      60
    ],
    "$": [
      60,
      60,
      60,
      60
    ],
    "%": [
      60,
      60,
      60,
      60
    ],
    "&": [
      60,
      60,
      60,
      60
    ],
    "'": [
      60,
      60,
      60,
      60
    ],
    "(": [
      60,
      60,
      60,
      60
    ],
    ")": [
      60,
      60,
      60,
      60
    ],
    "*": [
      60,
      60,
      60,
      60
    ],
    "+": [
      60,
      60,
      60,
      60
    ],
    ",": [
      60,
      60,
      60,
      60
    ],
    "-": [
      60,
      60,
      60,
      60
    ],
    ".": [
      60,
      60,
      60,
      60
    ],
    "/": [
      60,
      60,
      60,
      60
    ],
    ":": [
      60,
      60,
      60,
      60
    ],
    ";": [
      60,
      60,
      60,
      60
    ],
    "<": [
      60,
      60,
      60,
      60
    ],
    "=": [
      60,
      60,
      60,
      60
    ],
    ">": [
      60,
      60,
      60,
      60
    ],
    "?": [
      60,
      60,
      60,
      60
    ],
    "@": [
      60,
      60,
      60,
      60
    ],
    "A": [
      60,
      60,
      60,
      60
    ],
    "B": [
      60,
      60,
      60,
      60
    ],
    "C": [
      60,
      60,
      60,
      60
    ],
    "D": [
      60,
      60,
      60,
      60
    ],
    "E": [
      60,
      60,
      60,
      60
    ],
    "F": [
      60,
      60,
      60,
      60
    ],
    "G": [
      60,
      60,
      60,
      60
    ],
    "H": [
      60,
      60,
      60,
      60
    ],
    "I": [
      60,
      60,
      60,
      60
    ],
    "J": [
      60,
      60,
      60,
      60
    ],
    "K": [
      60,
      60,
      60,
      60
    ],
    "L": [
      60,
      60,
      60,
      60
    ],
    "M": [
      60,
      60,
      60,
      60
    ],
    "N": [
      60,
      60,
      60,
      60
    ],
    "O": [
      60,
      60,
      60,
      60
    ],
    "P": [
      60,
      60,
      60,
      60
    ],
    "Q": [
      60,
      60,
      60,
      60
    ],
    "R": [
      60,
      60,
      60,
      60
    ],
    "S": [
      60,
      60,
      60,
      60
    ],
    "T": [
      60,
      60,
      60,
      60
    ],
    "U": [
      60,
      60,
      60,
      60
    ],
    "V": [
      60,
      60,
      60,
      60
    ],
    "W": [
      60,
      60,
      60,
      60
    ],
    "X": [
      60,
      60,
      60,
      60
    ],
    "Y": [
      60,
      60,
      60,
      60
    ],
    "Z": [
      60,
      60,
      60,
      60
    ],
    "[": [
      60,
      60,
      60,
      60
    ],
    "\\": [
      60,
      60,
      60,
      60
    ],
    "]": [
      60,
      60,
      60,
      60
    ],
    "^": [
      60,
      60,
      60,
      60
    ],
    "_": [
      60,
      60,
      60,
      60
    ],
    "`": [
      60,
      60,
      60,
      60
    ],
    "a": [
      60,
      60,
      60,
      60
    ],
    "b": [
      60,
      60,
      60,
      60
    ],
    "c": [
      60,
      60,
      60,
      60
    ],
    "d": [
      60,
      60,
      60,
      60
    ],
    "e": [
      60,
      60,
      60,
      60
    ],
    "f": [
      60,
      60,
      60,
      60
    ],
    "g": [
      60,
      60,
      60,
      60
    ],
    "h": [
      60,
      60,
      60,
      60
    ],
    "i": [
      60,
      60,
      60,
      60
    ],
    "j": [
      60,
      60,
      60,
      60
    ],
    "k": [
      60,
      60,
      60,
      60
    ],
    "l": [
      60,
      60,
      60,
      60
    ],
    "m": [
      60,
      60,
      60,
      60
    ],
    "n": [
      60,
      60,
      60,
      60
    ],
    "o": [
      60,
      60,
      60,
      60
    ],
    "p": [
      60,
      60,
      60,
      60
    ],
    "q": [
      60,
      60,
      60,
      60
    ],
    "r": [
      60,
      60,
      60,
      60
    ],
    "s": [
      60,
      60,
      60,
      60
    ],
    "t": [
      60,
      60,
      60,
      60
    ],
    "u": [
      60,
      60,
      60,
      60
    ],
    "v": [
      60,
      60,
      60,
      60
    ],
    "w": [
      60,
      60,
      60,
      60
    ],
    "x": [
      60,
      60,
      60,
      60
    ],
    "y": [
      60,
      60,
      60,
      60
    ],
    "z": [
      60,
      60,
      60,
      60
    ],
    "{": [
      60,
      60,
      60,
      60
    ],
    "|": [
      60,
      60,
      60,
      60
    ],
    "}": [
      60,
      60,
      60,
      60
    ],
    "~": [
      60,
      60,
      60,
      60
    ]
  },
  "georgia": {
    "0": [
      61,
      70,
      61,
      70
    ],
    "1": [
      43,
      49,
      43,
      49
    ],
    "2": [
      56,
      63,
      56,
      63
    ],
    "3": [
      55,
      62,
      55,
      62
    ],
    "4": [
      56,
      65,
      56,
      65
    ],
    "5": [
      53,
      60,
      53,
      60
    ],
    "6": [
      57,
      65,
      57,
      65
    ],
    "7": [
      50,
      55,
      50,
      57
    ],
    "8": [
      60,
      68,
      60,
      68
    ],
    "9": [
      57,
      65,
      57,
      65
    ],
    " ": [
      24,
      25,
      24,
      25
    ],
    "!": [
      33,
      38,
      33,
      38
    ],
    "\"": [
      41,
      51,
      41,
      51
    ],
    "#": [
      64,
      70,
      64,
      70
    ],
    "$": [
      61,
      64,
      61,
      64
    ],
    "%": [
      82,
      88,
      82,
      88
    ],
    "&": [
      71,
      80,
      71,
      80
    ],
    "'": [
      22,
      27,
      22,
      27
    ],
    "(": [
      38,
      45,
      38,
      45
    ],
    ")": [
      38,
      45,
      38,
      45
    ],
    "*": [
      47,
      48,
      47,
      48
    ],
    "+": [
      64,
      70,
      64,
      70
    ],
    ",": [
      27,
      33,
      27,
      33
    ],
    "-": [
      37,
      38,
      37,
      38
    ],
    ".": [
      27,
      33,
      27,
      33
    ],
    "/": [
      47,
      47,
      47,
      47
    ],
    ":": [
      31,
      37,
      38,
      37
    ],
    ";": [
      31,
      37,
      38,
      37
    ],
    "<": [
      64,
      70,
      64,
      70
    ],
    "=": [
      64,
      70,
      64,
      70
    ],
    ">": [
      64,
      70,
      64,
      70
    ],
    "?": [
      48,
      55,
      48,
      55
    ],
    "@": [
      93,
      97,
      93,
      97
    ],
    "A": [
      67,
      76,
      67,
      76
    ],
    "B": [
      65,
      76,
      65,
      76
    ],
    "C": [
      64,
      72,
      64,
      72
    ],
    "D": [
      75,
      83,
      75,
      83
    ],
    "E": [
      65,
      72,
      65,
      72
    ],
    "F": [
      60,
      67,
      60,
      67
    ],
    "G": [
      73,
      81,
      73,
      81
    ],
    "H": [
      81,
      91,
      81,
      91
    ],
    "I": [
      39,
      45,
      39,
      45
    ],
    "J": [
      52,
      60,
      52,
      60
    ],
    "K": [
      69,
      82,
      69,
      82
    ],
    "L": [
      60,
      69,
      60,
      69
    ],
    "M": [
      93,
      102,
      93,
      103
    ],
    "N": [
      77,
      84,
      77,
      83
    ],
    "O": [
      74,
      82,
      73,
      82
    ],
    "P": [
      61,
      70,
      61,
      71
    ],
    "Q": [
      74,
      82,
      73,
      82
    ],
    "R": [
      70,
      80,
      70,
      80
    ],
    "S": [
      56,
      65,
      56,
      65
    ],
    "T": [
      62,
      68,
      62,
      68
    ],
    "U": [
      76,
      83,
      76,
      83
    ],
    "V": [
      67,
      76,
      67,
      76
    ],
    "W": [
      98,
      113,
      98,
      113
    ],
    "X": [
      71,
      81,
      71,
      80
    ],
    "Y": [
      62,
      73,
      62,
      73
    ],
    "Z": [
      60,
      69,
      60,
      69
    ],
    "[": [
      38,
      45,
      38,
      45
    ],
    "\\": [
      47,
      47,
      47,
      47
    ],
    "]": [
      38,
      45,
      38,
      45
    ],
    "^": [
      64,
      70,
      64,
      70
    ],
    "_": [
      64,
      70,
      64,
      70
    ],
    "`": [
      50,
      50,
      50,
      50
    ],
    "a": [
      50,
      60,
      57,
      66
    ],
    "b": [
      56,
      65,
      55,
      65
    ],
    "c": [
      45,
      53,
      45,
      54
    ],
    "d": [
      57,
      66,
      58,
      66
    ],
    "e": [
      48,
      57,
      47,
      56
    ],
    "f": [
      33,
      39,
      33,
      38
    ],
    "g": [
      51,
      58,
      57,
      65
    ],
    "h": [
      58,
      68,
      56,
      68
    ],
    "i": [
      29,
      35,
      30,
      37
    ],
    "j": [
      29,
      35,
      29,
      36
    ],
    "k": [
      54,
      63,
      53,
      64
    ],
    "l": [
      29,
      34,
      29,
      35
    ],
    "m": [
      88,
      102,
      88,
      100
    ],
    "n": [
      59,
      69,
      59,
      69
    ],
    "o": [
      54,
      64,
      54,
      64
    ],
    "p": [
      57,
      66,
      58,
      66
    ],
    "q": [
      56,
      65,
      56,
      65
    ],
    "r": [
      41,
      52,
      46,
      53
    ],
    "s": [
      43,
      51,
      43,
      52
    ],
    "t": [
      35,
      40,
      35,
      42
    ],
    "u": [
      58,
      68,
      58,
      69
    ],
    "v": [
      50,
      57,
      54,
      61
    ],
    "w": [
      74,
      86,
      82,
      93
    ],
    "x": [
      50,
      59,
      50,
      58
    ],
    "y": [
      49,
      56,
      56,
      67
    ],
    "z": [
      44,
      53,
      44,
      52
    ],
    "{": [
      43,
      50,
      43,
      50
    ],
    "|": [
      38,
      39,
      38,
      39
    ],
    "}": [
      43,
      50,
      43,
      50
    ],
    "~": [
      64,
      70,
      64,
      70
    ]
  },
  "helvetica": {
    "0": [
      56,
      56,
      56,
      56
    ],
    "1": [
      56,
      56,
      56,
      56
    ],
    "2": [
      56,
      56,
      56,
      56
    ],
    "3": [
      56,
      56,
      56,
      56
    ],
    "4": [
      56,
      56,
      56,
      56
    ],
    "5": [
      56,
      56,
      56,
      56
    ],
    "6": [
      56,
      56,
      56,
      56
    ],
    "7": [
      56,
      56,
      56,
      56
    ],
    "8": [
      56,
      56,
      56,
      56
    ],
    "9": [
      56,
      56,
      56,
      56
    ],
    " ": [
      28,
      28,
      28,
      28
    ],
    "!": [
      28,
      33,
      28,
      33
    ],
    "\"": [
      35,
      47,
      35,
      47
    ],
    "#": [
      56,
      56,
      56,
      56
    ],
    "$": [
      56,
      56,
      56,
      56
    ],
    "%": [
      89,
      89,
      89,
      89
    ],
    "&": [
      67,
      72,
      67,
      72
    ],
    "'": [
      19,
      24,
      19,
      24
    ],
    "(": [
      33,
      33,
      33,
      33
    ],
    ")": [
      33,
      33,
      33,
      33
    ],
    "*": [
      39,
      39,
      39,
      39
    ],
    "+": [
      58,
      58,
      58,
      58
    ],
    ",": [
      28,
      28,
      28,
      28
    ],
    "-": [
      33,
      33,
      33,
      33
    ],
    ".": [
      28,
      28,
      28,
      28
    ],
    "/": [
      28,
      28,
      28,
      28
    ],
    ":": [
      28,
      33,
      28,
      33
    ],
    ";": [
      28,
      33,
      28,
      33
    ],
    "<": [
      58,
      58,
      58,
      58
    ],
    "=": [
      58,
      58,
      58,
      58
    ],
    ">": [
      58,
      58,
      58,
      58
    ],
    "?": [
      56,
      61,
      56,
      61
    ],
    "@": [
      102,
      98,
      102,
      98
    ],
    "A": [
      67,
      72,
      67,
      72
    ],
    "B": [
      67,
      72,
      67,
      72
    ],
    "C": [
      72,
      72,
      72,
      72
    ],
    "D": [
      72,
      72,
      72,
      72
    ],
    "E": [
      67,
      67,
      67,
      67
    ],
    "F": [
      61,
      61,
      61,
      61
    ],
    "G": [
      78,
      78,
      78,
      78
    ],
    "H": [
      72,
      72,
      72,
      72
    ],
    "I": [
      28,
      28,
      28,
      28
    ],
    "J": [
      50,
      56,
      50,
      56
    ],
    "K": [
      67,
      72,
      67,
      72
    ],
    "L": [
      56,
      61,
      56,
      61
    ],
    "M": [
      83,
      83,
      83,
      83
    ],
    "N": [
      72,
      72,
      72,
      72
    ],
    "O": [
      78,
      78,
      78,
      78
    ],
    "P": [
      67,
      67,
      67,
      67
    ],
    "Q": [
      78,
      78,
      78,
      78
    ],
    "R": [
      72,
      72,
      72,
      72
    ],
    "S": [
      67,
      67,
      67,
      67
    ],
    "T": [
      61,
      61,
      61,
      61
    ],
    "U": [
      72,
      72,
      72,
      72
    ],
    "V": [
      67,
      67,
      67,
      67
    ],
    "W": [
      94,
      94,
      94,
      94
    ],
    "X": [
      67,
      67,
      67,
      67
    ],
    "Y": [
      67,
      67,
      67,
      67
    ],
    "Z": [
      61,
      61,
      61,
      61
    ],
    "[": [
      28,
      33,
      28,
      33
    ],
    "\\": [
      28,
      28,
      28,
      28
    ],
    "]": [
      28,
      33,
      28,
      33
    ],
    "^": [
      47,
      58,
      47,
      58
    ],
    "_": [
      56,
      56,
      56,
      56
    ],
    "`": [
      33,
      33,
      33,
      33
    ],
    "a": [
      56,
      56,
      56,
      56
    ],
    "b": [
      56,
      61,
      56,
      61
    ],
    "c": [
      50,
      56,
      50,
      56
    ],
    "d": [
      56,
      61,
      56,
      61
    ],
    "e": [
      56,
      56,
      56,
      56
    ],
    "f": [
      28,
      33,
      28,
      33
    ],
    "g": [
      56,
      61,
      56,
      61
    ],
    "h": [
      56,
      61,
      56,
      61
    ],
    "i": [
      22,
      28,
      22,
      28
    ],
    "j": [
      22,
      28,
      22,
      28
    ],
    "k": [
      50,
      56,
      50,
      56
    ],
    "l": [
      22,
      28,
      22,
      28
    ],
    "m": [
      83,
      89,
      83,
      89
    ],
    "n": [
      56,
      61,
      56,
      61
    ],
    "o": [
      56,
      61,
      56,
      61
    ],
    "p": [
      56,
      61,
      56,
      61
    ],
    "q": [
      56,
      61,
      56,
      61
    ],
    "r": [
      33,
      39,
      33,
      39
    ],
    "s": [
      50,
      56,
      50,
      56
    ],
    "t": [
      28,
      33,
      28,
      33
    ],
    "u": [
      56,
      61,
      56,
      61
    ],
    "v": [
      50,
      56,
      50,
      56
    ],
    "w": [
      72,
      78,
      72,
      78
    ],
    "x": [
      50,
      56,
      50,
      56
    ],
    "y": [
      50,
      56,
      50,
      56
    ],
    "z": [
      50,
      50,
      50,
      50
    ],
    "{": [
      33,
      39,
      33,
      39
    ],
    "|": [
      26,
      28,
      26,
      28
    ],
    "}": [
      33,
      39,
      33,
      39
    ],
    "~": [
      58,
      58,
      58,
      58
    ]
  },
  "impact": {
    "0": [
      54,
      54,
      54,
      54
    ],
    "1": [
      38,
      38,
      38,
      38
    ],
    "2": [
      50,
      50,
      50,
      50
    ],
    "3": [
      53,
      53,
      53,
      53
    ],
    "4": [
      50,
      50,
      50,
      50
    ],
    "5": [
      54,
      54,
      54,
      54
    ],
    "6": [
      54,
      54,
      54,
      54
    ],
    "7": [
      39,
      39,
      39,
      39
    ],
    "8": [
      53,
      53,
      53,
      53
    ],
    "9": [
      54,
      54,
      54,
      54
    ],
    " ": [
      18,
      18,
      18,
      18
    ],
    "!": [
      27,
      27,
      27,
      27
    ],
    "\"": [
      37,
      37,
      37,
      37
    ],
    "#": [
      63,
      63,
      63,
      63
    ],
    "$": [
      55,
      55,
      55,
      55
    ],
    "%": [
      69,
      69,
      69,
      69
    ],
    "&": [
      58,
      58,
      58,
      58
    ],
    "'": [
      19,
      19,
      19,
      19
    ],
    "(": [
      31,
      31,
      31,
      31
    ],
    ")": [
      31,
      31,
      31,
      31
    ],
    "*": [
      28,
      28,
      28,
      28
    ],
    "+": [
      53,
      53,
      53,
      53
    ],
    ",": [
      17,
      17,
      17,
      17
    ],
    "-": [
      29,
      29,
      29,
      29
    ],
    ".": [
      18,
      18,
      18,
      18
    ],
    "/": [
      40,
      40,
      40,
      40
    ],
    ":": [
      20,
      20,
      20,
      20
    ],
    ";": [
      20,
      20,
      20,
      20
    ],
    "<": [
      53,
      53,
      53,
      53
    ],
    "=": [
      53,
      53,
      53,
      53
    ],
    ">": [
      53,
      53,
      53,
      53
    ],
    "?": [
      52,
      52,
      52,
      52
    ],
    "@": [
      77,
      77,
      77,
      77
    ],
    "A": [
      51,
      51,
      51,
      51
    ],
    "B": [
      55,
      55,
      55,
      55
    ],
    "C": [
      55,
      55,
      55,
      55
    ],
    "D": [
      55,
      55,
      55,
      55
    ],
    "E": [
      42,
      42,
      42,
      42
    ],
    "F": [
      40,
      40,
      40,
      40
    ],
    "G": [
      55,
      55,
      55,
      55
    ],
    "H": [
      56,
      56,
      56,
      56
    ],
    "I": [
      29,
      29,
      29,
      29
    ],
    "J": [
      33,
      33,
      33,
      33
    ],
    "K": [
      54,
      54,
      54,
      54
    ],
    "L": [
      38,
      38,
      38,
      38
    ],
    "M": [
      72,
      72,
      72,
      72
    ],
    "N": [
      54,
      54,
      54,
      54
    ],
    "O": [
      55,
      55,
      55,
      55
    ],
    "P": [
      50,
      50,
      50,
      50
    ],
    "Q": [
      55,
      55,
      55,
      55
    ],
    "R": [
      54,
      54,
      54,
      54
    ],
    "S": [
      52,
      52,
      52,
      52
    ],
    "T": [
      46,
      46,
      46,
      46
    ],
    "U": [
      55,
      55,
      55,
      55
    ],
    "V": [
      52,
      52,
      52,
      52
    ],
    "W": [
      81,
      81,
      81,
      81
    ],
    "X": [
      48,
      48,
      48,
      48
    ],
    "Y": [
      47,
      47,
      47,
      47
    ],
    "Z": [
      40,
      40,
      40,
      40
    ],
    "[": [
      28,
      28,
      28,
      28
    ],
    "\\": [
      40,
      40,
      40,
      40
    ],
    "]": [
      28,
      28,
      28,
      28
    ],
    "^": [
      48,
      48,
      48,
      48
    ],
    "_": [
      55,
      55,
      55,
      55
    ],
    "`": [
      33,
      33,
      33,
      33
    ],
    "a": [
      50,
      50,
      50,
      50
    ],
    "b": [
      52,
      52,
      52,
      52
    ],
    "c": [
      49,
      49,
      49,
      49
    ],
    "d": [
      52,
      52,
      52,
      52
    ],
    "e": [
      51,
      51,
      51,
      51
    ],
    "f": [
      29,
      29,
      29,
      29
    ],
    "g": [
      52,
      52,
      52,
      52
    ],
    "h": [
      52,
      52,
      52,
      52
    ],
    "i": [
      27,
      27,
      27,
      27
    ],
    "j": [
      28,
      28,
      28,
      28
    ],
    "k": [
      48,
      48,
      48,
      48
    ],
    "l": [
      27,
      27,
      27,
      27
    ],
    "m": [
      77,
      77,
      77,
      77
    ],
    "n": [
      52,
      52,
      52,
      52
    ],
    "o": [
      51,
      51,
      51,
      51
    ],
    "p": [
      52,
      52,
      52,
      52
    ],
    "q": [
      52,
      52,
      52,
      52
    ],
    "r": [
      36,
      36,
      36,
      36
    ],
    "s": [
      47,
      47,
      47,
      47
    ],
    "t": [
      30,
      30,
      30,
      30
    ],
    "u": [
      52,
      52,
      52,
      52
    ],
    "v": [
      44,
      44,
      44,
      44
    ],
    "w": [
      67,
      67,
      67,
      67
    ],
    "x": [
      43,
      43,
      43,
      43
    ],
    "y": [
      45,
      45,
      45,
      45
    ],
    "z": [
      35,
      35,
      35,
      35
    ],
    "{": [
      37,
      37,
      37,
      37
    ],
    "|": [
      27,
      27,
      27,
      27
    ],
    "}": [
      37,
      37,
      37,
      37
    ],
    "~": [
      52,
      52,
      52,
      52
    ]
  },
  "times new roman": {
    "0": [
      50,
      50,
      50,
      50
    ],
    "1": [
      50,
      50,
      50,
      50
    ],
    "2": [
      50,
      50,
      50,
      50
    ],
    "3": [
      50,
      50,
      50,
      50
    ],
    "4": [
      50,
      50,
      50,
      50
    ],
    "5": [
      50,
      50,
      50,
      50
    ],
    "6": [
      50,
      50,
      50,
      50
    ],
    "7": [
      50,
      50,
      50,
      50
    ],
    "8": [
      50,
      50,
      50,
      50
    ],
    "9": [
      50,
      50,
      50,
      50
    ],
    " ": [
      25,
      25,
      25,
      25
    ],
    "!": [
      33,
      33,
      33,
      39
    ],
    "\"": [
      41,
      56,
      42,
      56
    ],
    "#": [
      50,
      50,
      50,
      50
    ],
    "$": [
      50,
      50,
      50,
      50
    ],
    "%": [
      83,
      100,
      83,
      83
    ],
    "&": [
      78,
      83,
      78,
      78
    ],
    "'": [
      18,
      28,
      21,
      28
    ],
    "(": [
      33,
      33,
      33,
      33
    ],
    ")": [
      33,
      33,
      33,
      33
    ],
    "*": [
      50,
      50,
      50,
      50
    ],
    "+": [
      56,
      57,
      67,
      57
    ],
    ",": [
      25,
      25,
      25,
      25
    ],
    "-": [
      33,
      33,
      33,
      33
    ],
    ".": [
      25,
      25,
      25,
      25
    ],
    "/": [
      28,
      28,
      28,
      28
    ],
    ":": [
      28,
      33,
      33,
      33
    ],
    ";": [
      28,
      33,
      33,
      33
    ],
    "<": [
      56,
      57,
      67,
      57
    ],
    "=": [
      56,
      57,
      67,
      57
    ],
    ">": [
      56,
      57,
      67,
      57
    ],
    "?": [
      44,
      50,
      50,
      50
    ],
    "@": [
      92,
      93,
      92,
      83
    ],
    "A": [
      72,
      72,
      61,
      67
    ],
    "B": [
      67,
      67,
      61,
      67
    ],
    "C": [
      67,
      72,
      67,
      67
    ],
    "D": [
      72,
      72,
      72,
      72
    ],
    "E": [
      61,
      67,
      61,
      67
    ],
    "F": [
      56,
      61,
      61,
      67
    ],
    "G": [
      72,
      78,
      72,
      72
    ],
    "H": [
      72,
      78,
      72,
      78
    ],
    "I": [
      33,
      39,
      33,
      39
    ],
    "J": [
      39,
      50,
      44,
      50
    ],
    "K": [
      72,
      78,
      67,
      67
    ],
    "L": [
      61,
      67,
      56,
      61
    ],
    "M": [
      89,
      94,
      83,
      89
    ],
    "N": [
      72,
      72,
      67,
      72
    ],
    "O": [
      72,
      78,
      72,
      72
    ],
    "P": [
      56,
      61,
      61,
      61
    ],
    "Q": [
      72,
      78,
      72,
      72
    ],
    "R": [
      67,
      72,
      61,
      67
    ],
    "S": [
      56,
      56,
      50,
      56
    ],
    "T": [
      61,
      67,
      56,
      61
    ],
    "U": [
      72,
      72,
      72,
      72
    ],
    "V": [
      72,
      72,
      61,
      67
    ],
    "W": [
      94,
      100,
      83,
      89
    ],
    "X": [
      72,
      72,
      61,
      67
    ],
    "Y": [
      72,
      72,
      56,
      61
    ],
    "Z": [
      61,
      67,
      56,
      61
    ],
    "[": [
      33,
      33,
      39,
      33
    ],
    "\\": [
      28,
      28,
      28,
      28
    ],
    "]": [
      33,
      33,
      39,
      33
    ],
    "^": [
      47,
      58,
      42,
      57
    ],
    "_": [
      50,
      50,
      50,
      50
    ],
    "`": [
      33,
      33,
      33,
      33
    ],
    "a": [
      44,
      50,
      50,
      50
    ],
    "b": [
      50,
      56,
      50,
      50
    ],
    "c": [
      44,
      44,
      44,
      44
    ],
    "d": [
      50,
      56,
      50,
      50
    ],
    "e": [
      44,
      44,
      44,
      44
    ],
    "f": [
      33,
      33,
      28,
      33
    ],
    "g": [
      50,
      50,
      50,
      50
    ],
    "h": [
      50,
      56,
      50,
      56
    ],
    "i": [
      28,
      28,
      28,
      28
    ],
    "j": [
      28,
      33,
      28,
      28
    ],
    "k": [
      50,
      56,
      44,
      50
    ],
    "l": [
      28,
      28,
      28,
      28
    ],
    "m": [
      78,
      83,
      72,
      78
    ],
    "n": [
      50,
      56,
      50,
      56
    ],
    "o": [
      50,
      50,
      50,
      50
    ],
    "p": [
      50,
      56,
      50,
      50
    ],
    "q": [
      50,
      56,
      50,
      50
    ],
    "r": [
      33,
      44,
      39,
      39
    ],
    "s": [
      39,
      39,
      39,
      39
    ],
    "t": [
      28,
      33,
      28,
      28
    ],
    "u": [
      50,
      56,
      50,
      56
    ],
    "v": [
      50,
      50,
      44,
      44
    ],
    "w": [
      72,
      72,
      67,
      67
    ],
    "x": [
      50,
      50,
      44,
      50
    ],
    "y": [
      50,
      50,
      44,
      44
    ],
    "z": [
      44,
      44,
      39,
      39
    ],
    "{": [
      48,
      39,
      40,
      35
    ],
    "|": [
      20,
      22,
      27,
      22
    ],
    "}": [
      48,
      39,
      40,
      35
    ],
    "~": [
      54,
      52,
      54,
      57
    ]
  },
  "trebuchet ms": {
    "0": [
      52,
      59,
      52,
      59
    ],
    "1": [
      52,
      59,
      52,
      59
    ],
    "2": [
      52,
      59,
      52,
      59
    ],
    "3": [
      52,
      59,
      52,
      59
    ],
    "4": [
      52,
      59,
      52,
      59
    ],
    "5": [
      52,
      59,
      52,
      59
    ],
    "6": [
      52,
      59,
      52,
      59
    ],
    "7": [
      52,
      59,
      52,
      59
    ],
    "8": [
      52,
      59,
      52,
      59
    ],
    "9": [
      52,
      59,
      52,
      59
    ],
    " ": [
      30,
      30,
      30,
      30
    ],
    "!": [
      37,
      37,
      37,
      37
    ],
    "\"": [
      32,
      37,
      32,
      39
    ],
    "#": [
      52,
      59,
      52,
      59
    ],
    "$": [
      52,
      59,
      48,
      59
    ],
    "%": [
      60,
      68,
      60,
      73
    ],
    "&": [
      71,
      71,
      71,
      71
    ],
    "'": [
      16,
      23,
      16,
      30
    ],
    "(": [
      37,
      37,
      37,
      37
    ],
    ")": [
      37,
      37,
      37,
      37
    ],
    "*": [
      37,
      43,
      37,
      43
    ],
    "+": [
      52,
      59,
      52,
      59
    ],
    ",": [
      37,
      37,
      37,
      37
    ],
    "-": [
      37,
      37,
      37,
      37
    ],
    ".": [
      37,
      37,
      37,
      37
    ],
    "/": [
      52,
      39,
      52,
      40
    ],
    ":": [
      37,
      37,
      37,
      37
    ],
    ";": [
      37,
      37,
      37,
      37
    ],
    "<": [
      52,
      59,
      52,
      59
    ],
    "=": [
      52,
      59,
      52,
      59
    ],
    ">": [
      52,
      59,
      52,
      59
    ],
    "?": [
      37,
      44,
      37,
      40
    ],
    "@": [
      77,
      77,
      77,
      77
    ],
    "A": [
      59,
      63,
      61,
      61
    ],
    "B": [
      57,
      60,
      57,
      59
    ],
    "C": [
      60,
      61,
      60,
      61
    ],
    "D": [
      61,
      64,
      61,
      63
    ],
    "E": [
      54,
      57,
      54,
      59
    ],
    "F": [
      52,
      58,
      52,
      59
    ],
    "G": [
      68,
      67,
      68,
      68
    ],
    "H": [
      65,
      68,
      65,
      68
    ],
    "I": [
      28,
      28,
      28,
      28
    ],
    "J": [
      48,
      53,
      48,
      50
    ],
    "K": [
      58,
      62,
      58,
      65
    ],
    "L": [
      51,
      55,
      51,
      53
    ],
    "M": [
      71,
      75,
      76,
      79
    ],
    "N": [
      64,
      67,
      64,
      66
    ],
    "O": [
      67,
      70,
      67,
      70
    ],
    "P": [
      56,
      59,
      54,
      58
    ],
    "Q": [
      68,
      71,
      67,
      77
    ],
    "R": [
      58,
      61,
      58,
      62
    ],
    "S": [
      48,
      51,
      48,
      50
    ],
    "T": [
      58,
      61,
      58,
      69
    ],
    "U": [
      65,
      68,
      65,
      66
    ],
    "V": [
      59,
      62,
      59,
      68
    ],
    "W": [
      85,
      88,
      85,
      93
    ],
    "X": [
      56,
      60,
      56,
      66
    ],
    "Y": [
      57,
      61,
      57,
      68
    ],
    "Z": [
      55,
      56,
      55,
      61
    ],
    "[": [
      37,
      40,
      37,
      49
    ],
    "\\": [
      36,
      36,
      36,
      48
    ],
    "]": [
      37,
      40,
      37,
      49
    ],
    "^": [
      52,
      59,
      52,
      59
    ],
    "_": [
      52,
      59,
      52,
      59
    ],
    "`": [
      52,
      59,
      52,
      59
    ],
    "a": [
      53,
      53,
      53,
      59
    ],
    "b": [
      56,
      58,
      56,
      59
    ],
    "c": [
      50,
      51,
      46,
      49
    ],
    "d": [
      56,
      58,
      56,
      59
    ],
    "e": [
      55,
      57,
      54,
      55
    ],
    "f": [
      37,
      37,
      40,
      41
    ],
    "g": [
      50,
      50,
      50,
      54
    ],
    "h": [
      55,
      59,
      56,
      56
    ],
    "i": [
      29,
      30,
      31,
      33
    ],
    "j": [
      37,
      37,
      37,
      39
    ],
    "k": [
      50,
      55,
      50,
      54
    ],
    "l": [
      29,
      29,
      32,
      32
    ],
    "m": [
      83,
      86,
      83,
      83
    ],
    "n": [
      55,
      59,
      55,
      56
    ],
    "o": [
      54,
      57,
      54,
      57
    ],
    "p": [
      56,
      58,
      56,
      60
    ],
    "q": [
      56,
      58,
      56,
      60
    ],
    "r": [
      39,
      43,
      42,
      45
    ],
    "s": [
      40,
      43,
      40,
      46
    ],
    "t": [
      40,
      40,
      42,
      44
    ],
    "u": [
      55,
      59,
      56,
      56
    ],
    "v": [
      49,
      53,
      49,
      55
    ],
    "w": [
      74,
      78,
      74,
      77
    ],
    "x": [
      50,
      55,
      50,
      58
    ],
    "y": [
      49,
      53,
      49,
      56
    ],
    "z": [
      47,
      53,
      47,
      53
    ],
    "{": [
      37,
      43,
      37,
      49
    ],
    "|": [
      52,
      59,
      52,
      59
    ],
    "}": [
      37,
      43,
      37,
      49
    ],
    "~": [
      52,
      59,
      52,
      59
    ]
  },
  "verdana": {
    "0": [
      64,
      71,
      64,
      71
    ],
    "1": [
      64,
      71,
      64,
      71
    ],
    "2": [
      64,
      71,
      64,
      71
    ],
    "3": [
      64,
      71,
      64,
      71
    ],
    "4": [
      64,
      71,
      64,
      71
    ],
    "5": [
      64,
      71,
      64,
      71
    ],
    "6": [
      64,
      71,
      64,
      71
    ],
    "7": [
      64,
      71,
      64,
      71
    ],
    "8": [
      64,
      71,
      64,
      71
    ],
    "9": [
      64,
      71,
      64,
      71
    ],
    " ": [
      35,
      34,
      35,
      34
    ],
    "!": [
      39,
      40,
      39,
      40
    ],
    "\"": [
      46,
      59,
      46,
      59
    ],
    "#": [
      82,
      87,
      82,
      87
    ],
    "$": [
      64,
      71,
      64,
      71
    ],
    "%": [
      108,
      127,
      108,
      127
    ],
    "&": [
      73,
      86,
      73,
      86
    ],
    "'": [
      27,
      33,
      27,
      33
    ],
    "(": [
      45,
      54,
      45,
      54
    ],
    ")": [
      45,
      54,
      45,
      54
    ],
    "*": [
      64,
      71,
      64,
      71
    ],
    "+": [
      82,
      87,
      82,
      87
    ],
    ",": [
      36,
      36,
      36,
      36
    ],
    "-": [
      45,
      48,
      45,
      48
    ],
    ".": [
      36,
      36,
      36,
      36
    ],
    "/": [
      45,
      69,
      45,
      69
    ],
    ":": [
      45,
      40,
      45,
      40
    ],
    ";": [
      45,
      40,
      45,
      40
    ],
    "<": [
      82,
      87,
      82,
      87
    ],
    "=": [
      82,
      87,
      82,
      87
    ],
    ">": [
      82,
      87,
      82,
      87
    ],
    "?": [
      55,
      62,
      55,
      62
    ],
    "@": [
      100,
      96,
      100,
      96
    ],
    "A": [
      68,
      78,
      68,
      78
    ],
    "B": [
      69,
      76,
      69,
      76
    ],
    "C": [
      70,
      72,
      70,
      72
    ],
    "D": [
      77,
      83,
      77,
      83
    ],
    "E": [
      63,
      68,
      63,
      68
    ],
    "F": [
      57,
      65,
      57,
      65
    ],
    "G": [
      78,
      81,
      78,
      81
    ],
    "H": [
      75,
      84,
      75,
      84
    ],
    "I": [
      42,
      55,
      42,
      55
    ],
    "J": [
      45,
      56,
      45,
      56
    ],
    "K": [
      69,
      77,
      69,
      77
    ],
    "L": [
      56,
      64,
      56,
      64
    ],
    "M": [
      84,
      95,
      84,
      95
    ],
    "N": [
      75,
      85,
      75,
      85
    ],
    "O": [
      79,
      85,
      79,
      85
    ],
    "P": [
      60,
      73,
      60,
      73
    ],
    "Q": [
      79,
      85,
      79,
      85
    ],
    "R": [
      70,
      78,
      70,
      78
    ],
    "S": [
      68,
      71,
      68,
      71
    ],
    "T": [
      62,
      68,
      62,
      68
    ],
    "U": [
      73,
      81,
      73,
      81
    ],
    "V": [
      68,
      76,
      68,
      76
    ],
    "W": [
      99,
      113,
      99,
      113
    ],
    "X": [
      69,
      76,
      69,
      76
    ],
    "Y": [
      62,
      74,
      62,
      74
    ],
    "Z": [
      69,
      69,
      69,
      69
    ],
    "[": [
      45,
      54,
      45,
      54
    ],
    "\\": [
      45,
      69,
      45,
      69
    ],
    "]": [
      45,
      54,
      45,
      54
    ],
    "^": [
      82,
      87,
      82,
      87
    ],
    "_": [
      64,
      71,
      64,
      71
    ],
    "`": [
      64,
      71,
      64,
      71
    ],
    "a": [
      60,
      67,
      60,
      67
    ],
    "b": [
      62,
      70,
      62,
      70
    ],
    "c": [
      52,
      59,
      52,
      59
    ],
    "d": [
      62,
      70,
      62,
      70
    ],
    "e": [
      60,
      66,
      60,
      66
    ],
    "f": [
      35,
      42,
      35,
      42
    ],
    "g": [
      62,
      70,
      62,
      70
    ],
    "h": [
      63,
      71,
      63,
      71
    ],
    "i": [
      27,
      34,
      27,
      34
    ],
    "j": [
      34,
      40,
      34,
      40
    ],
    "k": [
      59,
      67,
      59,
      67
    ],
    "l": [
      27,
      34,
      27,
      34
    ],
    "m": [
      97,
      106,
      97,
      106
    ],
    "n": [
      63,
      71,
      63,
      71
    ],
    "o": [
      61,
      69,
      61,
      69
    ],
    "p": [
      62,
      70,
      62,
      70
    ],
    "q": [
      62,
      70,
      62,
      70
    ],
    "r": [
      43,
      50,
      43,
      50
    ],
    "s": [
      52,
      59,
      52,
      59
    ],
    "t": [
      39,
      46,
      39,
      46
    ],
    "u": [
      63,
      71,
      63,
      71
    ],
    "v": [
      59,
      65,
      59,
      65
    ],
    "w": [
      82,
      98,
      82,
      98
    ],
    "x": [
      59,
      67,
      59,
      67
    ],
    "y": [
      59,
      65,
      59,
      65
    ],
    "z": [
      53,
      60,
      53,
      60
    ],
    "{": [
      63,
      71,
      63,
      71
    ],
    "|": [
      45,
      54,
      45,
      54
    ],
    "}": [
      63,
      71,
      63,
      71
    ],
    "~": [
      82,
      87,
      82,
      87
    ]
  },
  "webdings": {
    "0": [
      100,
      100,
      100,
      100
    ],
    "1": [
      100,
      100,
      100,
      100
    ],
    "2": [
      100,
      100,
      100,
      100
    ],
    "3": [
      100,
      100,
      100,
      100
    ],
    "4": [
      100,
      100,
      100,
      100
    ],
    "5": [
      100,
      100,
      100,
      100
    ],
    "6": [
      100,
      100,
      100,
      100
    ],
    "7": [
      100,
      100,
      100,
      100
    ],
    "8": [
      100,
      100,
      100,
      100
    ],
    "9": [
      100,
      100,
      100,
      100
    ],
    " ": [
      100,
      100,
      100,
      100
    ],
    "!": [
      100,
      100,
      100,
      100
    ],
    "\"": [
      100,
      100,
      100,
      100
    ],
    "#": [
      100,
      100,
      100,
      100
    ],
    "$": [
      100,
      100,
      100,
      100
    ],
    "%": [
      99,
      99,
      99,
      99
    ],
    "&": [
      100,
      100,
      100,
      100
    ],
    "'": [
      100,
      100,
      100,
      100
    ],
    "(": [
      100,
      100,
      100,
      100
    ],
    ")": [
      100,
      100,
      100,
      100
    ],
    "*": [
      100,
      100,
      100,
      100
    ],
    "+": [
      100,
      100,
      100,
      100
    ],
    ",": [
      100,
      100,
      100,
      100
    ],
    "-": [
      100,
      100,
      100,
      100
    ],
    ".": [
      100,
      100,
      100,
      100
    ],
    "/": [
      100,
      100,
      100,
      100
    ],
    ":": [
      100,
      100,
      100,
      100
    ],
    ";": [
      100,
      100,
      100,
      100
    ],
    "<": [
      100,
      100,
      100,
      100
    ],
    "=": [
      100,
      100,
      100,
      100
    ],
    ">": [
      100,
      100,
      100,
      100
    ],
    "?": [
      100,
      100,
      100,
      100
    ],
    "@": [
      100,
      100,
      100,
      100
    ],
    "A": [
      100,
      100,
      100,
      100
    ],
    "B": [
      100,
      100,
      100,
      100
    ],
    "C": [
      100,
      100,
      100,
      100
    ],
    "D": [
      100,
      100,
      100,
      100
    ],
    "E": [
      100,
      100,
      100,
      100
    ],
    "F": [
      100,
      100,
      100,
      100
    ],
    "G": [
      100,
      100,
      100,
      100
    ],
    "H": [
      100,
      100,
      100,
      100
    ],
    "I": [
      100,
      100,
      100,
      100
    ],
    "J": [
      100,
      100,
      100,
      100
    ],
    "K": [
      100,
      100,
      100,
      100
    ],
    "L": [
      100,
      100,
      100,
      100
    ],
    "M": [
      100,
      100,
      100,
      100
    ],
    "N": [
      100,
      100,
      100,
      100
    ],
    "O": [
      100,
      100,
      100,
      100
    ],
    "P": [
      100,
      100,
      100,
      100
    ],
    "Q": [
      100,
      100,
      100,
      100
    ],
    "R": [
      100,
      100,
      100,
      100
    ],
    "S": [
      100,
      100,
      100,
      100
    ],
    "T": [
      100,
      100,
      100,
      100
    ],
    "U": [
      100,
      100,
      100,
      100
    ],
    "V": [
      100,
      100,
      100,
      100
    ],
    "W": [
      54,
      54,
      54,
      54
    ],
    "X": [
      54,
      54,
      54,
      54
    ],
    "Y": [
      100,
      100,
      100,
      100
    ],
    "Z": [
      100,
      100,
      100,
      100
    ],
    "[": [
      100,
      100,
      100,
      100
    ],
    "\\": [
      100,
      100,
      100,
      100
    ],
    "]": [
      100,
      100,
      100,
      100
    ],
    "^": [
      100,
      100,
      100,
      100
    ],
    "_": [
      100,
      100,
      100,
      100
    ],
    "`": [
      100,
      100,
      100,
      100
    ],
    "a": [
      100,
      100,
      100,
      100
    ],
    "b": [
      100,
      100,
      100,
      100
    ],
    "c": [
      100,
      100,
      100,
      100
    ],
    "d": [
      100,
      100,
      100,
      100
    ],
    "e": [
      100,
      100,
      100,
      100
    ],
    "f": [
      100,
      100,
      100,
      100
    ],
    "g": [
      100,
      100,
      100,
      100
    ],
    "h": [
      100,
      100,
      100,
      100
    ],
    "i": [
      100,
      100,
      100,
      100
    ],
    "j": [
      100,
      100,
      100,
      100
    ],
    "k": [
      100,
      100,
      100,
      100
    ],
    "l": [
      100,
      100,
      100,
      100
    ],
    "m": [
      56,
      56,
      56,
      56
    ],
    "n": [
      100,
      100,
      100,
      100
    ],
    "o": [
      100,
      100,
      100,
      100
    ],
    "p": [
      100,
      100,
      100,
      100
    ],
    "q": [
      100,
      100,
      100,
      100
    ],
    "r": [
      100,
      100,
      100,
      100
    ],
    "s": [
      100,
      100,
      100,
      100
    ],
    "t": [
      100,
      100,
      100,
      100
    ],
    "u": [
      100,
      100,
      100,
      100
    ],
    "v": [
      100,
      100,
      100,
      100
    ],
    "w": [
      100,
      100,
      100,
      100
    ],
    "x": [
      100,
      100,
      100,
      100
    ],
    "y": [
      100,
      100,
      100,
      100
    ],
    "z": [
      100,
      100,
      100,
      100
    ],
    "{": [
      100,
      100,
      100,
      100
    ],
    "|": [
      100,
      100,
      100,
      100
    ],
    "}": [
      100,
      100,
      100,
      100
    ],
    "~": [
      100,
      100,
      100,
      100
    ]
  },
  "open sans": {
    "0": [
      57,
      57,
      55,
      55
    ],
    "1": [
      57,
      57,
      55,
      55
    ],
    "2": [
      57,
      57,
      55,
      55
    ],
    "3": [
      57,
      57,
      55,
      55
    ],
    "4": [
      57,
      57,
      55,
      55
    ],
    "5": [
      57,
      57,
      55,
      55
    ],
    "6": [
      57,
      57,
      55,
      55
    ],
    "7": [
      57,
      57,
      55,
      55
    ],
    "8": [
      57,
      57,
      55,
      55
    ],
    "9": [
      57,
      57,
      55,
      55
    ],
    " ": [
      26,
      26,
      26,
      26
    ],
    "!": [
      27,
      29,
      26,
      29
    ],
    "\"": [
      40,
      47,
      39,
      45
    ],
    "#": [
      65,
      65,
      65,
      65
    ],
    "$": [
      57,
      57,
      55,
      55
    ],
    "%": [
      82,
      90,
      79,
      86
    ],
    "&": [
      73,
      75,
      67,
      71
    ],
    "'": [
      22,
      27,
      22,
      25
    ],
    "(": [
      30,
      34,
      29,
      34
    ],
    ")": [
      30,
      34,
      29,
      34
    ],
    "*": [
      55,
      54,
      55,
      54
    ],
    "+": [
      57,
      57,
      55,
      55
    ],
    ",": [
      25,
      29,
      24,
      28
    ],
    "-": [
      32,
      32,
      31,
      32
    ],
    ".": [
      27,
      29,
      25,
      29
    ],
    "/": [
      37,
      41,
      35,
      42
    ],
    ":": [
      27,
      29,
      25,
      29
    ],
    ";": [
      27,
      29,
      25,
      29
    ],
    "<": [
      57,
      57,
      55,
      55
    ],
    "=": [
      57,
      57,
      55,
      55
    ],
    ">": [
      57,
      57,
      55,
      55
    ],
    "?": [
      43,
      48,
      43,
      46
    ],
    "@": [
      90,
      90,
      85,
      86
    ],
    "A": [
      63,
      69,
      56,
      63
    ],
    "B": [
      65,
      67,
      60,
      62
    ],
    "C": [
      63,
      64,
      58,
      61
    ],
    "D": [
      73,
      74,
      67,
      68
    ],
    "E": [
      56,
      56,
      51,
      54
    ],
    "F": [
      52,
      55,
      47,
      53
    ],
    "G": [
      73,
      72,
      68,
      69
    ],
    "H": [
      74,
      77,
      68,
      70
    ],
    "I": [
      28,
      33,
      27,
      32
    ],
    "J": [
      27,
      33,
      27,
      33
    ],
    "K": [
      61,
      66,
      56,
      61
    ],
    "L": [
      52,
      56,
      47,
      52
    ],
    "M": [
      90,
      94,
      84,
      88
    ],
    "N": [
      75,
      81,
      70,
      75
    ],
    "O": [
      78,
      80,
      72,
      73
    ],
    "P": [
      60,
      63,
      57,
      58
    ],
    "Q": [
      78,
      80,
      72,
      73
    ],
    "R": [
      62,
      66,
      57,
      61
    ],
    "S": [
      55,
      55,
      50,
      53
    ],
    "T": [
      55,
      58,
      50,
      53
    ],
    "U": [
      73,
      76,
      68,
      69
    ],
    "V": [
      60,
      65,
      55,
      59
    ],
    "W": [
      93,
      97,
      85,
      89
    ],
    "X": [
      58,
      67,
      52,
      61
    ],
    "Y": [
      56,
      62,
      50,
      56
    ],
    "Z": [
      57,
      58,
      53,
      54
    ],
    "[": [
      33,
      33,
      29,
      33
    ],
    "\\": [
      37,
      41,
      35,
      42
    ],
    "]": [
      33,
      33,
      29,
      33
    ],
    "^": [
      54,
      53,
      52,
      53
    ],
    "_": [
      45,
      41,
      39,
      40
    ],
    "`": [
      58,
      61,
      55,
      55
    ],
    "a": [
      56,
      60,
      56,
      59
    ],
    "b": [
      61,
      63,
      58,
      60
    ],
    "c": [
      48,
      51,
      45,
      48
    ],
    "d": [
      61,
      63,
      58,
      59
    ],
    "e": [
      56,
      59,
      49,
      56
    ],
    "f": [
      34,
      39,
      31,
      37
    ],
    "g": [
      55,
      56,
      50,
      54
    ],
    "h": [
      61,
      66,
      58,
      60
    ],
    "i": [
      25,
      31,
      25,
      30
    ],
    "j": [
      25,
      31,
      25,
      30
    ],
    "k": [
      52,
      62,
      49,
      57
    ],
    "l": [
      25,
      31,
      25,
      30
    ],
    "m": [
      93,
      98,
      87,
      90
    ],
    "n": [
      61,
      66,
      58,
      60
    ],
    "o": [
      60,
      62,
      56,
      58
    ],
    "p": [
      61,
      63,
      58,
      60
    ],
    "q": [
      61,
      63,
      58,
      59
    ],
    "r": [
      41,
      45,
      40,
      42
    ],
    "s": [
      48,
      50,
      43,
      47
    ],
    "t": [
      35,
      43,
      32,
      41
    ],
    "u": [
      61,
      66,
      58,
      60
    ],
    "v": [
      50,
      57,
      46,
      51
    ],
    "w": [
      78,
      86,
      72,
      79
    ],
    "x": [
      52,
      58,
      48,
      53
    ],
    "y": [
      50,
      57,
      46,
      52
    ],
    "z": [
      47,
      49,
      44,
      46
    ],
    "{": [
      38,
      39,
      35,
      35
    ],
    "|": [
      55,
      55,
      55,
      55
    ],
    "}": [
      38,
      39,
      35,
      35
    ],
    "~": [
      57,
      57,
      55,
      55
    ]
  },
  "tahoma": {
    "0": [
      55,
      64,
      55,
      64
    ],
    "1": [
      55,
      64,
      55,
      64
    ],
    "2": [
      55,
      64,
      55,
      64
    ],
    "3": [
      55,
      64,
      55,
      64
    ],
    "4": [
      55,
      64,
      55,
      64
    ],
    "5": [
      55,
      64,
      55,
      64
    ],
    "6": [
      55,
      64,
      55,
      64
    ],
    "7": [
      55,
      64,
      55,
      64
    ],
    "8": [
      55,
      64,
      55,
      64
    ],
    "9": [
      55,
      64,
      55,
      64
    ],
    " ": [
      31,
      29,
      31,
      29
    ],
    "!": [
      33,
      34,
      33,
      34
    ],
    "\"": [
      40,
      49,
      40,
      49
    ],
    "#": [
      73,
      82,
      73,
      82
    ],
    "$": [
      55,
      64,
      55,
      64
    ],
    "%": [
      98,
      120,
      98,
      120
    ],
    "&": [
      67,
      78,
      67,
      78
    ],
    "'": [
      21,
      28,
      21,
      28
    ],
    "(": [
      38,
      45,
      38,
      45
    ],
    ")": [
      38,
      45,
      38,
      45
    ],
    "*": [
      55,
      64,
      55,
      64
    ],
    "+": [
      73,
      82,
      73,
      82
    ],
    ",": [
      30,
      31,
      30,
      31
    ],
    "-": [
      36,
      43,
      36,
      43
    ],
    ".": [
      30,
      31,
      30,
      31
    ],
    "/": [
      38,
      58,
      38,
      58
    ],
    ":": [
      35,
      36,
      35,
      36
    ],
    ";": [
      35,
      36,
      35,
      36
    ],
    "<": [
      73,
      82,
      73,
      82
    ],
    "=": [
      73,
      82,
      73,
      82
    ],
    ">": [
      73,
      82,
      73,
      82
    ],
    "?": [
      47,
      57,
      47,
      57
    ],
    "@": [
      91,
      92,
      91,
      92
    ],
    "A": [
      60,
      68,
      60,
      68
    ],
    "B": [
      59,
      69,
      59,
      69
    ],
    "C": [
      60,
      67,
      60,
      67
    ],
    "D": [
      68,
      76,
      68,
      76
    ],
    "E": [
      56,
      62,
      56,
      62
    ],
    "F": [
      52,
      58,
      52,
      58
    ],
    "G": [
      67,
      75,
      67,
      75
    ],
    "H": [
      68,
      76,
      68,
      76
    ],
    "I": [
      37,
      48,
      37,
      48
    ],
    "J": [
      42,
      50,
      42,
      50
    ],
    "K": [
      59,
      70,
      59,
      70
    ],
    "L": [
      50,
      57,
      50,
      57
    ],
    "M": [
      77,
      89,
      77,
      89
    ],
    "N": [
      67,
      77,
      67,
      77
    ],
    "O": [
      71,
      77,
      71,
      77
    ],
    "P": [
      55,
      66,
      55,
      66
    ],
    "Q": [
      71,
      77,
      71,
      77
    ],
    "R": [
      62,
      73,
      62,
      73
    ],
    "S": [
      56,
      63,
      56,
      63
    ],
    "T": [
      58,
      61,
      58,
      61
    ],
    "U": [
      66,
      74,
      66,
      74
    ],
    "V": [
      60,
      67,
      60,
      67
    ],
    "W": [
      90,
      103,
      90,
      103
    ],
    "X": [
      58,
      68,
      58,
      68
    ],
    "Y": [
      58,
      67,
      58,
      67
    ],
    "Z": [
      56,
      62,
      56,
      62
    ],
    "[": [
      38,
      45,
      38,
      45
    ],
    "\\": [
      38,
      58,
      38,
      58
    ],
    "]": [
      38,
      45,
      38,
      45
    ],
    "^": [
      73,
      82,
      73,
      82
    ],
    "_": [
      55,
      64,
      55,
      64
    ],
    "`": [
      55,
      55,
      55,
      55
    ],
    "a": [
      52,
      60,
      52,
      60
    ],
    "b": [
      55,
      63,
      55,
      63
    ],
    "c": [
      46,
      53,
      46,
      53
    ],
    "d": [
      55,
      63,
      55,
      63
    ],
    "e": [
      53,
      59,
      53,
      59
    ],
    "f": [
      32,
      38,
      32,
      38
    ],
    "g": [
      55,
      63,
      55,
      63
    ],
    "h": [
      56,
      64,
      56,
      64
    ],
    "i": [
      23,
      30,
      23,
      30
    ],
    "j": [
      28,
      36,
      28,
      36
    ],
    "k": [
      50,
      60,
      50,
      60
    ],
    "l": [
      23,
      30,
      23,
      30
    ],
    "m": [
      84,
      95,
      84,
      95
    ],
    "n": [
      56,
      64,
      56,
      64
    ],
    "o": [
      54,
      62,
      54,
      62
    ],
    "p": [
      55,
      63,
      55,
      63
    ],
    "q": [
      55,
      63,
      55,
      63
    ],
    "r": [
      36,
      43,
      36,
      43
    ],
    "s": [
      45,
      51,
      45,
      51
    ],
    "t": [
      33,
      42,
      33,
      42
    ],
    "u": [
      56,
      64,
      56,
      64
    ],
    "v": [
      50,
      58,
      50,
      58
    ],
    "w": [
      74,
      89,
      74,
      89
    ],
    "x": [
      50,
      60,
      50,
      60
    ],
    "y": [
      50,
      58,
      50,
      58
    ],
    "z": [
      44,
      53,
      44,
      53
    ],
    "{": [
      48,
      62,
      48,
      62
    ],
    "|": [
      38,
      64,
      38,
      64
    ],
    "}": [
      48,
      62,
      48,
      62
    ],
    "~": [
      73,
      82,
      73,
      82
    ]
  },
  "quantify": {
    "0": [
      57,
      57,
      57,
      57
    ],
    "1": [
      40,
      40,
      40,
      40
    ],
    "2": [
      54,
      54,
      54,
      54
    ],
    "3": [
      54,
      54,
      54,
      54
    ],
    "4": [
      61,
      61,
      61,
      61
    ],
    "5": [
      54,
      54,
      54,
      54
    ],
    "6": [
      53,
      53,
      53,
      53
    ],
    "7": [
      52,
      52,
      52,
      52
    ],
    "8": [
      53,
      53,
      53,
      53
    ],
    "9": [
      53,
      53,
      53,
      53
    ],
    " ": [
      20,
      20,
      20,
      20
    ],
    "!": [
      24,
      24,
      24,
      24
    ],
    "\"": [
      46,
      46,
      46,
      46
    ],
    "#": [
      77,
      77,
      77,
      77
    ],
    "$": [
      60,
      60,
      60,
      60
    ],
    "%": [
      102,
      102,
      102,
      102
    ],
    "&": [
      68,
      68,
      68,
      68
    ],
    "'": [
      24,
      24,
      24,
      24
    ],
    "(": [
      33,
      33,
      33,
      33
    ],
    ")": [
      33,
      33,
      33,
      33
    ],
    "*": [
      30,
      30,
      30,
      30
    ],
    "+": [
      58,
      58,
      58,
      58
    ],
    ",": [
      24,
      24,
      24,
      24
    ],
    "-": [
      51,
      51,
      51,
      51
    ],
    ".": [
      24,
      24,
      24,
      24
    ],
    "/": [
      69,
      69,
      69,
      69
    ],
    ":": [
      24,
      24,
      24,
      24
    ],
    ";": [
      24,
      24,
      24,
      24
    ],
    "<": [
      46,
      46,
      46,
      46
    ],
    "=": [
      61,
      61,
      61,
      61
    ],
    ">": [
      46,
      46,
      46,
      46
    ],
    "?": [
      54,
      54,
      54,
      54
    ],
    "@": [
      83,
      83,
      83,
      83
    ],
    "A": [
      78,
      78,
      78,
      78
    ],
    "B": [
      63,
      63,
      63,
      63
    ],
    "C": [
      65,
      65,
      65,
      65
    ],
    "D": [
      70,
      70,
      70,
      70
    ],
    "E": [
      54,
      54,
      54,
      54
    ],
    "F": [
      52,
      52,
      52,
      52
    ],
    "G": [
      78,
      78,
      78,
      78
    ],
    "H": [
      60,
      60,
      60,
      60
    ],
    "I": [
      20,
      20,
      20,
      20
    ],
    "J": [
      54,
      54,
      54,
      54
    ],
    "K": [
      60,
      60,
      60,
      60
    ],
    "L": [
      45,
      45,
      45,
      45
    ],
    "M": [
      77,
      77,
      77,
      77
    ],
    "N": [
      60,
      60,
      60,
      60
    ],
    "O": [
      78,
      78,
      78,
      78
    ],
    "P": [
      57,
      57,
      57,
      57
    ],
    "Q": [
      80,
      80,
      80,
      80
    ],
    "R": [
      58,
      58,
      58,
      58
    ],
    "S": [
      55,
      55,
      55,
      55
    ],
    "T": [
      57,
      57,
      57,
      57
    ],
    "U": [
      65,
      65,
      65,
      65
    ],
    "V": [
      69,
      69,
      69,
      69
    ],
    "W": [
      99,
      99,
      99,
      99
    ],
    "X": [
      70,
      70,
      70,
      70
    ],
    "Y": [
      70,
      70,
      70,
      70
    ],
    "Z": [
      57,
      57,
      57,
      57
    ],
    "[": [
      32,
      32,
      32,
      32
    ],
    "\\": [
      68,
      68,
      68,
      68
    ],
    "]": [
      32,
      32,
      32,
      32
    ],
    "^": [
      56,
      56,
      56,
      56
    ],
    "_": [
      72,
      72,
      72,
      72
    ],
    "`": [
      32,
      32,
      32,
      32
    ],
    "a": [
      58,
      58,
      58,
      58
    ],
    "b": [
      58,
      58,
      58,
      58
    ],
    "c": [
      47,
      47,
      47,
      47
    ],
    "d": [
      58,
      58,
      58,
      58
    ],
    "e": [
      57,
      57,
      57,
      57
    ],
    "f": [
      28,
      28,
      28,
      28
    ],
    "g": [
      57,
      57,
      57,
      57
    ],
    "h": [
      53,
      53,
      53,
      53
    ],
    "i": [
      19,
      19,
      19,
      19
    ],
    "j": [
      19,
      19,
      19,
      19
    ],
    "k": [
      50,
      50,
      50,
      50
    ],
    "l": [
      19,
      19,
      19,
      19
    ],
    "m": [
      76,
      76,
      76,
      76
    ],
    "n": [
      53,
      53,
      53,
      53
    ],
    "o": [
      57,
      57,
      57,
      57
    ],
    "p": [
      58,
      58,
      58,
      58
    ],
    "q": [
      58,
      58,
      58,
      58
    ],
    "r": [
      32,
      32,
      32,
      32
    ],
    "s": [
      43,
      43,
      43,
      43
    ],
    "t": [
      30,
      30,
      30,
      30
    ],
    "u": [
      53,
      53,
      53,
      53
    ],
    "v": [
      61,
      61,
      61,
      61
    ],
    "w": [
      81,
      81,
      81,
      81
    ],
    "x": [
      59,
      59,
      59,
      59
    ],
    "y": [
      61,
      61,
      61,
      61
    ],
    "z": [
      46,
      46,
      46,
      46
    ],
    "{": [
      29,
      29,
      29,
      29
    ],
    "|": [
      22,
      22,
      22,
      22
    ],
    "}": [
      29,
      29,
      29,
      29
    ],
    "~": [
      62,
      62,
      62,
      62
    ]
  }
};

const settingsDefaults = { font: 'Arial', size: 10 };
const getWidth = (string, settings) => {
  const sett = { ...settingsDefaults, ...settings };
  const font = sett.font.toLowerCase();
  const size = sett.size;
  const variant = 0 + (sett.bold ? 1 : 0) + (sett.italic ? 2 : 0);
  const map = sett.map || widthsMap;
  const available = Object.keys(map);
  if (available.indexOf(font) === -1) {
    throw new Error(`This font is not supported. Supported fonts are: ${available.join(', ')}`);
  }
  let totalWidth = 0;

  string.split('').forEach((char) => {
    const widths = map[font][char] || map[font].x;
    const width = widths[variant];
    totalWidth += width;
    return true;
  });
  return totalWidth * (size / 100);
};


export {getSeries, getLeastSquares, getWidth}