export default {
    // Presentation
    PRESENTATION_ZOOM : 'PRESENTATION_ZOOM',
    PRESENTATION_FADE : 'PRESENTATION_FADE',
    PRESENTATION_APPEAR : 'PRESENTATION_APPEAR',
    PRESENTATION_FLYIN : 'PRESENTATION_FLYIN',
    PRESENTATION_FLYOUT : 'PRESENTATION_FLYOUT',
    PRESENTATION_FADEIN : 'PRESENTATION_FADEIN',
    PRESENTATION_FADEOUT : 'PRESENTATION_FADEOUT',
    PRESENTATION_ZOOMIN : 'PRESENTATION_ZOOMIN',
    PRESENTATION_ZOOMOUT : 'PRESENTATION_ZOOMOUT',
    // Interpretation
    INTERPRETATION_FLICKER : 'INTERPRETATION_FLICKER',
    INTERPRETATION_PATH : 'INTERPRETATION_PATH',
    // Reasoning
    REASONING_HIGHLIGHT: 'REASONING_HIGHLIGHT',
    REASONING_FILLCOLOR: 'REASONING_FILLCOLOR',
}