import React, { Component } from 'react';
import HeaderBar from '../HeaderBar';
import './projectview.css';

export default class ProjectView extends Component {
    render() {
        return (
            <div>
                <HeaderBar/>
                ProjectView
            </div>
        )
    }
}
