const animate = (animation, props) => {

    switch (animation.type) {
        
    
        default:
            break;
    }
}

const configure = (animation, props) => {

    switch (animation.type) {
        
    
        default:
            return null;
    }
}

export {animate, configure};