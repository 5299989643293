const apikey = '2816b9fe39b50c987cd07b04438c2c048bd9f63e2feb3013555ec5970988b194'
const userFolderId = '4962'

var config = {
    baseurl: '',
    rootFolderId:'4961',
    apikey: apikey,
    userFolderId: userFolderId,
    api: {
        asset: '/webservice/rest/asset',
        assetList: '/webservice/rest/asset-list'
    }
}

export default config;