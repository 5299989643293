//undraw data list 
const test = [
[
    "app-installation",
    "artificial-intelligence",
     "at-work",
    "back-in-the-day",
    "bitcoin",
    "bitcoin-p2p",
    "browser-stats",
    "buffer",
    "bug-fixing",
    "business-deal",
    "business-plan",
    "businessman",
    "businesswoman",
    "charts",
    "checking-boxes",
    "checklist",
    "cloud-hosting",
    "cloud-sync",
    "co-workers",
    "co-working",
    "code-typing",
    "coding",
    "collaboration",
    "completed",
    "confirmation",
    "confirmed",
    "connected",
    "connected-world",
    "connecting-teams",
    "contact-us",
    "control-panel",
    "credit-card",
    "credit-card-payment",
    "credit-card-payments",
    "customer-survey",
    "dark-analytics",
    "dashboard",
    "data",
    "data-points",
    "data-report",
    "data-trends",
    "developer-activity",
    "development",
    "devices",
    "digital-nomad",
    "domain-names",
    "download",
    "drone-delivery",
    "drone-race",
    "email-campaign",
    "email-capture",
    "emails",
    "envelope",
    "fast-car",
    "fast-loading",
    "features-overview",
    "file-bundle",
    "file-searching",
    "files-sent",
    "filing-system",
    "filter",
    "financial-data",
    "fingerprint",
    "firmware",
    "folder",
    "follow-me-drone",
    "forgot-password",
    "goal",
    "going-up",
    "group-chat",
    "group-selfie",
    "growing",
    "growth-analytics",
    "in-progress",
    "in-sync",
    "in-the-office",
    "inbox-cleanup",
    "influencer",
    "internet-on-the-go",
    "investing",
    "java-script-frameworks",
    "knowledge",
    "laravel-and-vue",
    "loading",
    "login",
    "logistics",
    "lost",
    "mail",
    "mail-sent",
    "mailbox",
    "map",
    "map-dark",
    "map-light",
    "meeting",
    "memory-storage",
    "message-sent",
    "messages",
    "messaging",
    "messaging-fun",
    "messenger",
    "mind-map",
    "mobile",
    "mobile-browsers",
    "mobile-apps",
    "mobile-life",
    "mobile-marketing",
    "mobile-payments",
    "mobile-testing",
    "monitor",
    "multitasking",
    "my-password",
    "navigation",
    "new-message",
    "no-data",
    "not-found",
    "note-list",
    "notebook",
    "notes",
    "notify",
    "on-the-office",
    "online",
    "online-page",
    "online-world",
    "open-source",
    "operating-system",
    "order-confirmed",
    "organize-photos",
    "organize-resume",
    "organizing-projects",
    "page-not-found",
    "pair-programming",
    "static-assets",
    "pen-tool",
    "personal-data",
    "personal-notes",
    "personal-settings",
    "personal-site",
    "personal-trainer",
    "personalization",
    "pie-chart",
    "plain-credit-card",
    "post",
    "post-online",
    "posting-photo",
    "posts",
    "printing-invoices",
    "problem-solving",
    "processing",
    "product-hunt",
    "product-teardown",
    "product-tour",
    "profile-data",
    "profile-pic",
    "programmer",
    "programming",
    "projections",
    "prototyping-process",
    "qa-engineers",
    "questions",
    "react",
    "real-time-sync",
    "refreshing",
    "robotics",
    "schedule",
    "science",
    "scooter",
    "scrum-board",
    "search",
    "search-engines",
    "secure-data",
    "security",
    "secure-server",
    "segment",
    "segmentation",
    "server",
    "server-down",
    "server-status",
    "setup",
    "setup-analytics",
    "setup-wizard",
    "site-stats",
    "sleep-analysis",
    "smart-home",
    "social-dashboard",
    "social-growth",
    "social-ideas",
    "social-life",
    "social-media",
    "social-networking",
    "social-share",
    "social-strategy",
    "social-tree",
    "social-update",
    "software-engineer",
    "specs",
    "spreadsheets",
    "static-assets",
    "statistics",
    "status-update",
    "sync",
    "synchronize",
    "team",
    "team-page",
    "team-spirit",
    "team-work",
    "time-management",
    "timeline",
    "to-do",
    "to-do-list",
    "track-and-field",
    "transfer-files",
    "typewriter",
    "typing",
    "unboxing",
    "update",
    "upgrade",
    "upload",
    "upload-image",
    "uploading",
    "upvote",
    "usability-testing",
    "user-flow",
    "vault",
    "version-control",
    "video-call",
    "videographer",
    "virtual-reality",
    "visual-data",
    "voice-control",
    "vr-chat",
    "wall-post",
    "warning",
    "weather-app",
    "web-devices",
    "website-setup",
    "window-shopping",
    "wordpress",
    "work-chat",
    "work-time",
],
   [
"astronaut",
"appreciation",
"barber",
"be-the-hero",
"baby",
"beer-celebration",
"back-to-school",
"begin-chat",
"birthday-cake",
"book-lover",
"booking",
"brainstorming",
"building-blocks",
"broadcast",
"buddies",
"by-my-car",
"calling",
"campfire",
"camping",
"cancel",
"candidate",
"celebration",
"chat",
"chatting",
"chilling",
"choice",
"choose",
"christmas-stocking",
"christmas-tree",
"coffee-break",
"contemplating",
"conversation",
"discount",
"dog-walking",
"doll-play",
"dreamer",
"easter-egg-hunt",
"eating-together",
"ethereum",
"exams",
"exploring",
"festivities",
"fireworks",
"followers",
"following",
"fitness-tracker",
"for-sale",
"getting-coffee",
"girls-just-wanna-have-fun",
"grades",
"graduation",
"grandma",
"hamburger",
"hang-out",
"old-day",
"happy-birthday",
"heartbroken",
"hello",
"hire",
"hiring",
"horror-movie",
"house-searching",
"in-love",
"in-the-pool",
"in-thought",
"instant-support",
"instruction-manual",
"interview",
"invite",
"japan",
"jason-mask",
"job-hunt",
"journey",
"judge",
"learning",
"launching",
"live-collaboration",
"love",
"love-is-in-the-air",
"maintenance",
"maker-launch",
"marilyn",
"marketing",
"medicine",
"millennial-girl",
"mindfulness",
"mint-tea",
"missed-chances",
"mission-impossible",
"modern-life",
"modern-woman",
"moment-to-remember",
"motherhood",
"movie-night",
"moving-forward",
"nerd",
"news",
"newsletter",
"night-calls",
"ninja",
"old-day",
"on-the-way",
"online-friends",
"online-shopping",
"online-wishes",
"opened",
"ordinary-day",
"palette",
"passing-by",
"pedestrian-crossing",
"people-search",
"photo",
"photo-sharing",
"photocopy",
"photos",
"podcast",
"podcast-audience",
"powerful",
"preferences",
"presentation",
"press-play",
"profile",
"queue",
"quitting-time",
"recording",
"relaxation",
"relaxing-at-home",
"report",
"responsive",
"resume",
"resume-folder",
"romantic-getaway",
"safe",
"santa-claus",
"savings",
"select",
"selfie",
"selfie-time",
"shopping",
"smiley-face",
"speech-to-text",
"starman",
"startled",
"startup-life",
"staying-in",
"street-food",
"stripe-payments",
"studying",
"subscriber",
"successful-purchase",
"super-thank-you",
"super-woman",
"superhero",
"surfer",
"swipe-profiles",
"taking-notes",
"taken",
"target",
"task",
"tasting",
"teacher",
"teaching",
"texting",
"thoughts",
"throw-down",
"together",
"toy-car",
"travel-booking",
"travelers",
"traveling",
"treasure",
"trip",
"true-friends",
"tweetstorm",
"under-construction",
"vehicle-sale",
"wedding",
"welcome",
"winners",
"winter-designer",
"winter-olympics",
"wireframing",
"wishes",
"wishlist",
"witch",
"woman",
"women-day",
"word-of-mouth",
"working",
"working-late",
"working-remotely",
"workout",
"world",
"xmas-surprise",
"yacht",
"young-and-happy",
"youtube-tutorial",
   ],
   [
"balloons",
"basketball",
"children",
"dua-lipa",
"ether",
"finish-line",
"floating",
"fishing",
"i-can-fly",
"grand-slam",
"game-day",
"gaming",
"healthy-habit",
"high-five",
"hiking",
"home-run",
"jogging",
"joyride",
"may-the-force",
"meditating",
"meditation",
"pilates",
"rising",
"ride-a-bicycle",
"running-wild",
"skateboarding",
"stability-ball",
"walk-in-the-city",
"wallet",
   ],
   [
"around-the-world",
"autumn",
"beach",
"before-dawn",
"bus-stop",
"building",
"calculator",
"calendar",
"career-progress",
"cautious-dog",
"chef",
"city-driver",
"city-girl",
"collecting",
"collection",
"community",
"conference",
"conference-speaker",
"container-ship",
"country-side",
"couple",
"crypto-flowers",
"dark-alley",
"departing",
"depi",
"destination",
"directions",
"doctor",
"doctors",
"electric-car",
"events",
"environment",
"experts",
"fall-is-coming",
"fans",
"fatherhood",
"feeling-blue",
"finance",
"fish-bowl",
"forever",
"freelancer",
"friendship",
"frozen-figure",
"gardening",
"gdpr",
"gift",
"gift-card",
"gifts",
"golden-gate-bridge",
"good-doggy",
"houses",
"into-the-night",
"late-at-night",
"lighthouse",
"make-it-rain",
"morning-essentials",
"off-road",
"onboarding",
"outer-space",
"playful-cat",
"revenue",
"snowman",
"step-to-the-sun",
"subway",
"sunny-day",
"teddy-bear",
"through-the-desert",
"to-the-stars",
"weather",
"wind-turbine",
"windows",
   ],
   [
"art",
"art-lover",
"artist",
"authentication",
"awards",
"bibliophile",
"blank-canvas",
"blogging",
"blog-post",
"bookmarks",
"circles",
"compose-music",
"content",
"content-creator",
"contrast",
"create",
"creation-process",
"creative-thinking",
"creative-woman",
"creative-team",
"creativity",
"convert",
"deliveries",
"delivery",
"design-community",
"design-process",
"design-thinking",
"design-tools",
"designer",
"designer-girl",
"designer-life",
"documents",
"done",
"elements",
"empty",
"everyday-design",
"fashion-blogging",
"focus",
"image-folder",
"image-post",
"image-upload",
"images",
"interaction-design",
"makeup-artist",
"making-art",
"media-player",
"metrics",
"more-music",
"music",
"portfolio",
"reading-list",
"sculpting",
   ],
];

export default test;