const defaultSpec = {
    "encoding": {
        "area": { "field": "Province", "type": "categorical" },
        "color": {"field": "Confirmed", "type": "nordinal"},
    },
    "style": {
    },
    "animation": []
}

export default defaultSpec;