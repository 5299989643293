export default {
    OPEN_EDITOR: 'OPEN_EDITOR',
    // Data
    ADD_DATA: 'ADD_DATA',
    SWITCH_DATA: 'SWITCH_DATA',
    UPDATE_DATA: 'UPDATE_DATA',
    DELETE_DATA: 'DELETE_DATA',
    // Exploration
    ENCODING: 'ADD_ENCODING',
    MODIFY_ENCODING: 'MODIFY_ENCODING',
    REMOVE_ENCODING: 'REMOVE_ENCODING',
    CHANGE_AGGREGATION: 'CHANGE_AGGREGATION',
    // Style
    CONFIGURE_STYLE: 'CONFIGURE_STYLE',
    // Animation
    CHOOSE_CHART_ANIMATION: 'CHOOSE_CHART_ANIMATION', // Start dragging one animation to chart
    SELECT_CHART_ANIMATION: 'SELECT_CHART_ANIMATION', // Select one animation to configure
    SELECTING_CHART_ELEMENT: 'SELECTING_CHART_ELEMENT',
    ADD_CHART_ANIMATION: 'ADD_CHART_ANIMATION',
    MODIFY_CHART_ANIMATION: 'MODIFY_CHART_ANIMATION',
    REMOVE_CHART_ANIMATION: 'REMOVE_CHART_ANIMATION',
    REORDER_CHART_ANIMATION: 'REORDER_CHART_ANIMATION',
    UPDATE_CHART_ANIMATION_VIDEO_URL : 'UPDATE_CHART_ANIMATION_VIDEO_URL',
    // Meta
    UNDO_VIS: 'UNDO_VIS',
    REDO_VIS: 'REDO_VIS',
    // Insight
    BOOKMARK: 'BOOKMARK',
    SAVE: 'SAVE',
}