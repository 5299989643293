export default {
    // UI Action
    DISPLAY_SIDEBAR : 'SHOW_SIDEBAR',
    DISPLAY_TRACK_EDITOR : 'DISPLAY_TRACK_EDITOR',
    DISPLAY_STORYLINE : 'DISPLAY_STORYLINE',
    DISPLAY_RESOURCE_PANE : 'DISPLAY_RESOURCE_PANE',
    DISPLAY_TOOL_PANE : 'DISPLAY_TOOL_PANE',
    DISPLAY_ANIMATION_TARGET_AREA : 'DISPLAY_ANIMATION_TARGET_AREA',
    DISPLAY_PATHLAYER:"DISPLAY_PATHLAYER",
    DISPLAY_RESOURCE_TARGET_AREA : 'DISPLAY_RESOURCE_TARGET_AREA',
    DISPLAY_MUSIC_TARGET_AREA : 'DISPLAY_MUSIC_TARGET_AREA',
    // Play Control Action
    PLAY_VIDEO : 'PLAY_VIDEO',
    PAUSE_VIDEO : 'PAUSE_VIDEO',
    STOP_VIDEO : 'STOP_VIDEO',
    PLAY_SCENE : 'PLAY_SCENE',
    PAUSE_SCENE : 'PAUSE_SCENE',
    STOP_SCENE : 'STOP_SCENE',
    // Video Action
    SELECT_SCENE: 'SELECT_SCENE',
    ADD_SCENE : 'ADD_SCENE',
    REMOVE_SCENE : 'REMOVE_SCENE',
    UPDATE_SCENE : 'UPDATE_SCENE',
    REORDER_SCENE : 'REORDER_SCENES',
    ADD_BACKGROUND_MUSIC: 'ADD_BACKGROUND_MUSIC',
    // Canvas Action
    SELECT_ELEMENT: 'SELECT_ELEMENT',
    UNSELECT_ELEMENT: 'UNSELECT_ELEMENT',
    ADD_ELEMENT: 'ADD_ELEMENT',
    UPDATE_ELEMENT: 'UPDATE_ELEMENT',
    REMOVE_ELEMENT: 'REMOVE_ELEMENT',
    REORDER_ELEMENT: 'REORDER_ELEMENT',
    DRAG_ELEMENT:'DRAG_ELEMENT',
    TRANSFORM_ELEMENT:'TRANSFORM_ELEMENT',
    CLEAN_INTERACTION_LAYER:'CLEAN_INTERACTION_LAYER',
    // Scene Action
    SET_DURATION: 'SET_DURATION',
    SET_POSITION: 'SET_POSITION',
    SET_SCENE_SCALE: 'SET_SCENE_SCALE',
    // Meta Action
    UNDO_CANVAS: 'UNDO_CANVAS',
    REDO_CANVAS: 'REDO_CANVAS',
    //Project Action
    ADD_PROJECT: "ADD_PROJECT",
    REMOVE_PROJECT: "REMOVE_PROJECT",
}