const draw = (props) => {
    // TODO: Get selecting parameter
    // let selectingParameter = props.selectingParameter;
    // let selectType = selectingParameter.type;

    // TODO: modify chart animation
    // props.modifyChartAnimation(props.selectedAnimationIndex, animation);

    // TODO: unselect
    // props.selectChartElement(false, {});

}

export default draw;